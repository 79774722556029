const initialState = {
    petData: [],
};

const petReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PET_DATA':
            return {
                ...state,
                petData: action.payload,
            };
        case 'CLEAR_PET_DATA':
            return {
                ...state,
                petData: [],
            };
        default:
            return state;
    }
}

export default petReducer;