import React, {useEffect, useState} from "react";
import * as ForumApi from "../api/apiForum.js";
import * as analitycs from "../api/apiAnalytics.js";
import ForoItem from "../components/foros/foroItem.tsx";
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import "./foros.css"
import { Timestamp } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { addForoData, clearForoData, setForoData } from "../redux/actions/foroActions.js";
import { Fab, TextField } from "@mui/material";


type SelectedForo ={
    id: string,
    title: string,
    description: string,
    created: string,
    autor: string
}

type msgForo = {
    id: string,
    autor: string,
    comentario: string,
    date: Timestamp,
}


/**
 * Foros component
 * @description pagina de foros
 * @returns {JSX.Element} Foros component
 * */
const Foros = () => {
    const user = useSelector((state: any) => state.user.userData );
    const foros = useSelector((state: any) => state.foro );
    const dispatch = useDispatch();
    const [newForo, setNewForo] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    async function getForos() {
        let FOROS = await ForumApi.getForums();
        if (FOROS) {
            dispatch(clearForoData());
            dispatch(setForoData(FOROS));
        }                 
    }

    useEffect(() => {
        /* const now = new Date();
        const updated = new Date(foros.update);
        if (foros.foroData.length === 0 || now.getTime() - updated.getTime() > 60000) {
            getForos();
        } */
        getForos();
    }, []);

    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    const handleCreateForo = async() => {
        if (title.length > 0 && description.length > 0) {
            let result = await ForumApi.createForum(title,description, user.id);
            if (result) {
                setNewForo(false);  
                setTitle("");
                setDescription("");
                getForos();
                analitycs.sendCreateForumEvent(user.id)
            }
        }
    }

    const handleNewForo = () => {
        setNewForo(true);
    }

    return (
        <>
        <section style={{textAlign: "center"}}>
        <h2>Foros</h2>
        </section>

        {user !== null &&
        <>
        <Fab style={{
                position: "fixed",
                bottom: 20,
                right: 20,
                borderRadius: "50%",
                border: "none",
                backgroundColor: "lightgray",
                color: "black",
                padding: 10,
                fontSize: 15,
                cursor: "pointer",
            }} 
            onClick={() => {
                handleNewForo();
            }}>
            <FontAwesomeIcon icon={faPlus} size="3x" />
        </Fab>
        <Modal 
            isOpen={newForo} onRequestClose={()=>setNewForo(false)}
            contentLabel="Nuevo Foro" className="foro-modal"
            style={{
                overlay: {
                    zIndex: 2,
                }
            }}
        >
            <section style={{
                backgroundColor: "white",
                width: "90%",
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 15,
                maxWidth: 600,
                padding: 25,
                border: "1px solid black",
                overflow: "auto",
                maxHeight: "90vh",
            }}>
            <button onClick={()=>setNewForo(false)} style={{
                border: "none",
                backgroundColor: "transparent",
                position: "absolute",
                top: 10,
                right: 10,
            }}>
                <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
            <h2 style={{marginTop: 5}}>Nuevo Foro</h2>
            <form style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                height: "100%",
            }} onSubmit={(e) => {
                e.preventDefault();
                handleCreateForo();
            }} >
                <TextField
                    id="title"
                    required
                    label="Titulo"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{marginBottom: 10}}
                />
                <TextField
                    id="mensaje"
                    multiline
                    fullWidth
                    rows={6}
                    required
                    label="Mensaje"
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{marginBottom: 10}}
                />
                <button type="submit" 
                    style={{
                        backgroundColor: "white",
                            borderRadius: 20,
                            padding: 10,
                            border: "1px solid #ccc",
                            cursor: "pointer",
                            width: 200,
                            alignSelf: "center",
                            marginTop: 10,
                    }}>Crear</button>
            </form>
            </section>
        </Modal>
        </>
        }
        <div style={{minHeight: "100vh", maxWidth: 1000, margin: "auto",}}>
            {foros.foroData.map(foro => 
                (
                <ForoItem
                    userID={user?.id || ""}
                    foro={foro} 
                    />
                )
            )
            }
        </div>
        </>
    );
};

export default Foros;