/** 
 * NosotrosItem component
 * @description Componente que muestra la información de un miembro del equipo
 * @param {string} imagen - la imagen del miembro
 * @param {string} nombre - el nombre del miembro
 * @param {string} puesto - el puesto del miembro
 * @module NosotrosItem 
 */
const NosotrosItem = ({ligthImage, imagen = null, nombre="", puesto =""}) => {
    return (
        <div style={{
            backgroundColor: "#FFBD59",
            borderRadius: 10,
            height: 425,
            width: 290,
            textAlign: "center",
            margin: 15,
            paddingBottom: 10,
        }}>
            <picture>
                <source srcSet={ligthImage} type="image/avif"/>
                <img
                    style={{
                        borderRadius: 10,
                        height: 300,
                        width: 250,
                        margin: 20,
                        marginBottom: 0,
                    }}
                    src={imagen}
                    alt={"foto de "+nombre}
                />
            </picture>
            <h3>{nombre}</h3>
            <p style={{textAlign: "center",}}>{puesto}</p>
        </div>
    );
}

export default NosotrosItem;