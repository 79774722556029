import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPaperPlane, faHeart, faComment, faUser } from "@fortawesome/free-solid-svg-icons";
import * as ForumApi from "../../api/apiForum";
import * as analitycs from "../../api/apiAnalytics.js";
import ReactLoading from 'react-loading';
import {TextField } from '@mui/material';
import { useSelector } from "react-redux";
import { apiURL } from "../../api/api.js";
import "./foroItem.css";




/**
 * ForoItemProps interface
 */
interface ForoItemProps {
  foro: any; //foro a mostrar
  onclick: Function; //función que se ejecuta al dar click
}

type msgForo = {
  id: string,
  author: string,
  content: string,
  created_at: Date,
}


/**
 * ForoItem component
 * @param {any} foro - foro a mostrar
 * @param {Function} onclick - función que se ejecuta al dar click
 * @param {string} userID - id del usuario
 * @return {JSX.Element} - ForoItem component
 */
const ForoItem = ({ foro,userID }) => {
  const [userMsg, setUserMsg] = useState("");
  const user = useSelector((state: any) => state.user.userData );
  const [liked, setLiked] = useState(false);
  const [showingComments, setShowingComments] = useState(false);
  const [msgForo, setMsgForo] = useState<Array<msgForo>>([]);
  const [loading, setLoading] = useState(false);

  async function getMsgForo(id) {
    let MSG = await ForumApi.getComments(id);
    if (MSG) {
        MSG = MSG.sort((a, b) => {
            return a.date - b.date;
        });
    }
    setMsgForo(MSG);
  }

  const handleCreateMsg = async() => {
    if (userMsg.length > 0) {
        setLoading(true);
        let result = await ForumApi.createComments(foro.id, userMsg, user.id);
        setLoading(false);
        if (result) {
            setUserMsg("");
            getMsgForo(foro.id);
        }
    } 
  }

  /**
   * Funcion para calcular el tiempo transcurrido desde la creación del foro
   */
  const timeSince = (date: Date) => {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " años";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " meses";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " días";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " horas";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutos";
    }
    return Math.floor(seconds) + " segundos";
  }

  /**
   * Funcion para redondear los comentarios y likes cuando son mayores a 1000, 1000000
   */
  const round = (num: number) => {
    if (num >= 1000000) {
      return Math.round(num / 1000000) + "Millones";
    }
    if (num >= 10000) {
      return Math.round(num / 1000) + "mil";
    }
    return num;
  }

  useEffect(() => {
    setMsgForo([]);
    if (foro.id !== null) {
        getMsgForo(foro.id); // 
    }
}, [foro]);

  return (
    <>
    <section className="foro-item">
      <div className="foro-item-container">
      <div className="foro-item-header">
        <h5 style={{margin: 0, textAlign: "left", fontSize: 14}} >{foro.author}: </h5>
        <p style={{margin: 0, fontSize: 14}}> hace: {timeSince(foro.created_at)} </p>
      </div>
      <div className="foro-item-content">
      <figure
        style={{
          height: "50px",
          width: "50px",
          margin: 0,
        }}>
          {(foro.author_image == null || foro.author_image == "" || foro.author_image == undefined) ? (
            <FontAwesomeIcon icon={faUser} color="black" style={{fontSize: "50px", borderRadius: "50%", margin: 0, overflow: "hidden", height:  50, width: 50}} />
          ) : (
            <img
              src={apiURL + "/media/" + foro.author_image}
              alt="user"
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50%",
              }}
            />
          )}
      </figure>
      <article style={{textAlign: "left", width: "100%"}}>
        <h3 style={{textAlign: "left", margin: "5px 15px 5px"}}>{foro.title}</h3>
        <p style={{margin: "0px 10px",}}>{foro.description}</p>
      </article>
      <div style={{display: "flex", justifyContent: "left", alignItems: "center",margin: "0 5%"}}>
        <button 
          onClick={() => {
            if (userID === "") return;
            if (liked) {
              ForumApi.removeLikeToForum(userID, foro.id);
              setLiked(false);
              foro.likes -= 1;
            } else {
              ForumApi.giveLikeToForum(userID, foro.id);
              setLiked(true);
              foro.likes += 1;
            }
          }}
          style={{
            margin: 5,
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
          <FontAwesomeIcon icon={faHeart} size="2x" color={userID === "" ? "gray" : liked ? "red" : "black"} />
          <p>{round(foro.likes)}</p>
        </button>
        <button onClick={() => {
          setShowingComments(!showingComments)
          analitycs.sendWatchForumEvent(foro.id)
          }}
          style={{
            margin: 5,
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
          <FontAwesomeIcon icon={faComment} size="2x" />
          <p>{round(foro.comments)}</p>
        </button>
      </div>
      </div>
      <div style={{width: "90%", margin:"auto"}}>
        {showingComments && 
        <>
        <div style={{display: "flex",
        justifyContent: "left",
        flexDirection: "column",
        alignContent: "start"
        }}>
          <div style={{width: "100%", height: "1px", backgroundColor: "grey", borderRadius: "1px", marginBottom: "10px"}}></div>
          {msgForo.map(msg => 
          <div 
            style={{backgroundColor: "rgba(0, 0, 201, 0)", textAlign: "left", display: "flex", flexDirection: "row", alignItems: "center", maxWidth: "50%"
          }}>
            <p style={{fontSize: 13}}>{msg.author+" (hace: "+timeSince(new Date(msg.created_at))+"): "}</p> 
          <p>{msg.content}</p>
          </div>
        )}
        </div>
          {user !== null && <div className="foro-send_msg">
          <form onSubmit={(e) => {
              e.preventDefault();
              handleCreateMsg();
          }
          }>
              <TextField
                id="msg"
                label="Mensaje"
                multiline
                rows={1}
                required
                autoComplete="off"
                value={userMsg}
                onChange={(e) => setUserMsg(e.target.value)}
                style={{width: "100%"}}
              />
              <button type="submit" disabled={loading}>
                {loading ?
                <ReactLoading type={"spin"} color={"black"} height={20} width={20} />
                :
                  <FontAwesomeIcon 
                      icon={faPaperPlane}
                      size="2x"
                      style={{
                          color: "black",
                      }}
                  />
                }
              </button>
          </form>
      </div>}
      </>
        }
      </div>
    </div>
    </section>
    </>
  );
};

export default ForoItem;