import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import { getAuth} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./firebaseConfig";
import { getStorage} from "firebase/storage";

const DEBUG = false;
export let apiURL = DEBUG ? "http://localhost:8000" : "https://api.pawzone.cl";

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const db = getFirestore(app);

// Inicializa Auth
const auth = getAuth(app);

// Opcional: Inicializa Firebase Analytics
const analytics = getAnalytics(app);

// Create a root reference
const storage = getStorage();

//funciones para el manejo de la base de datos

export const contactForm = async (name, email, msg) => {
    try {
        const response = await fetch('https://api.pawzone.cl/contact/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: msg
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error('Error en la solicitud a la API');
            return false;
        }
    }   catch (error) {
        console.error('Error al enviar la solicitud a la API', error);
        return false;
    }
}


export { db, auth, analytics, storage };