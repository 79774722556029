import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './navbar.css';
import pawzoneLogo from '../resources/pawzone-logo.avif';
import pawzoneLogoPng from '../resources/pawzone-logo.png';


/**
 * NavbarProps interface
 */
interface NavbarProps {
  loginClick: Function; //función que se ejecuta para iniciar sesión
  isLogged: boolean; //indica si el usuario está logueado
  user: any; //información del usuario logueado
}

/** 
 * Navbar component
 * @param {Function} loginClick - función que se ejecuta para iniciar sesión
 * @param {boolean} isLogged - indica si el usuario está logueado
 * @param {any} user - información del usuario logueado
 * @return {JSX.Element} - Navbar component
 * */
const Navbar = ({loginClick, isLogged=false, user}: NavbarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false); //indica si el menú está abierto o cerrado cuando se esta en mobile

  const toggleHamburgerMenu = () => setIsOpen(!isOpen); //función para abrir o cerrar el menú

  return (
    <nav className="navbar">
      <Link to="/" className="nav-logo">
        <picture>
          <source srcSet={pawzoneLogoPng} type="image/png" />
          <img src={pawzoneLogo} alt="logo" height={75} />
        </picture>
      </Link>
      <div className="hamburger" onClick={toggleHamburgerMenu}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </div>
      <ul className={isOpen ? "nav-links-mobile active" : "nav-links"}>
        {/* cerrar el menu cuando se seleccione un link */}
        {!isLogged &&
          <li onClick={()=>setIsOpen(false)}><Link to="/">Inicio</Link></li>
        }
        {/* se cancela los servicios por ahora */}
        {/* <li onClick={()=>setIsOpen(false)}><Link to="/servicios">Servicios</Link></li> */}
        <li onClick={()=>setIsOpen(false)}><Link to="/foros">Foros</Link></li>
        
        {isLogged ? (
          <>
            {user?.user_type === "Pet lover" && <li onClick={()=>setIsOpen(false)}><Link to="/mascotas">Mi mascota</Link></li>}
            {/* {user?.userType === "Colaborador" && <li onClick={()=>setIsOpen(false)}><Link to="/miServicio">Mi Servicio</Link></li>} */}
            <li onClick={()=>setIsOpen(false)}><Link to="/perfil">Perfil</Link></li>
          </>
        ) : (
          <>
            {/* <li onClick={()=>setIsOpen(false)}><Link to="/colaboradores">Colaboradores</Link></li> */}
            <li onClick={()=>{loginClick(true); setIsOpen(false);}}>Ficha Mascota</li>
            <li><button onClick={()=>{loginClick(true); setIsOpen(false);}} className="login-button">Iniciar sesión</button></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;