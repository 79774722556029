import React, { useEffect, useState } from 'react';
import * as PetApi from "../../../api/apiPet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch } from 'react-redux';
import { setPetData } from "../../../redux/actions/petActions.js";
import dayjs from 'dayjs';
import "./vaccine.css"

const Vaccines = ({ vaccines, pet, userId, setVaccines }) => {
    const [openForm, setOpenForm] = useState(false);
    const [newVaccine, setNewVaccine] = useState({
        date: null,
        name: "",
        reminder: "",
        error: "",
    });
    const [structureVaccines, setStructureVaccines] = useState([]);
    const [showVaccines, setShowVaccines] = useState([]);
    const [loading , setLoading] = useState(false);
    const [edditing, setEdditing] = useState(false);
    const today = new Date().toISOString().split("T")[0];
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (pet?.species === "gato") {
            setOptions(["Rabia", "Triple felina"]);
        } else if (pet?.species === "perro") {
            setOptions(["Rabia", "Sextuple", "Octuple"]);
        }
    }, [pet]);

    const handleAddVaccine = async (e) => {
        e.preventDefault();
        if (!newVaccine.date) {
            setNewVaccine({...newVaccine, error: "La fecha no puede estar vacía"});
            return;
        } else if (!newVaccine.name) {
            setNewVaccine({...newVaccine, error: "El nombre no puede estar vacío"});
            return;
        }
        newVaccine.name = newVaccine.name.trim();
        //newVaccine.date = new Date(newVaccine.date);
        setLoading(true);
        const response = await PetApi.addVaccineToPet(pet.id, newVaccine.date, newVaccine.name, userId, newVaccine.reminder);
        setLoading(false);
        if (response) {
            setVaccines([...vaccines, {
                name: newVaccine.name,
                date: new Date(newVaccine.date).toLocaleDateString(),
                creatorId: userId,
            }]);
            setNewVaccine({
                date: null,
                name: "",
                reminder: "",
                error: "",
                creatorId: userId,
            });
            setOpenForm(false);
            reloadPets();
        } else {
            alert("Error al añadir la vacuna");
        }
    }

    // recargar la lista de mascotas
    const reloadPets = async () => {
        const pets = await PetApi.getPetsByOwner(userId);
        dispatch(setPetData(pets));
    }

    const handleDeleteVaccine = async (vaccineId) => {
        setLoading(true);
        const response = await PetApi.deleteVaccineFromPet(vaccineId, userId);
        setLoading(false);
        if (response) {
            const newVaccines = vaccines.filter((v) => v.id !== vaccineId);
            setVaccines(newVaccines);
        } else {
            alert("Error al eliminar la vacuna");
        }
    }

    const showVaccineRecommendation = (vaccine) => {
        if (pet?.species === "gato") {
            if (vaccine.name === "Rabia" || vaccine.name === "Triple felina") {
                return true;
            }
            return false;
        }
        if (pet?.species === "perro") {
            if (vaccine.name === "Rabia" || vaccine.name === "Sextuple" || vaccine.name === "Octuple") {
                return true;
            }
            return false;
        }
        return false;
    }

    // chequea si la vacuna esta al dia
    const checkVaccine = (name) => {
        /// funcion auxiliaar que chequea si a pasado mas de un año desde la ultima vacuna
        const moreThanAYear = (vaccine) => {
            if (vaccine.length > 0) {
                const lastVaccine = vaccine[0];
                const lastVaccineDate = new Date(lastVaccine.date);
                const today = new Date();
                const diff = today.getTime() - lastVaccineDate.getTime();
                const diffDays = diff / (1000 * 60 * 60 * 24);
                if (diffDays > 365) {
                    return false;
                }
                return true;
            }
            return false;
        }

        if (pet?.species === "gato") {
            if (name === "Rabia") {
                //buscamos en structureVaccines la vacuna de la rabia
                const vaccines = structureVaccines.find((v) => v.name === "Rabia")?.dates;
                return moreThanAYear(vaccines);
            } else if (name === "Triple felina") {
                //buscamos en structureVaccines la vacuna de la triple felina
                const vaccines = structureVaccines.find((v) => v.name === "Triple felina")?.dates;
                return moreThanAYear(vaccines);
            }
            return false;
        } else if (pet?.species === "perro") {
            if (name === "Rabia") {
                //buscamos en structureVaccines la vacuna de la rabia
                const vaccines = structureVaccines.find((v) => v.name === "Rabia")?.dates;
                return moreThanAYear(vaccines);
            } else if (name === "Sextuple" || name === "Octuple"){
                //buscamos en structureVaccines la vacuna de la sextuple u octuple
                const vaccines = structureVaccines.find((v) => v.name === "Sextuple" || v.name === "Octuple")?.dates;
                return moreThanAYear(vaccines);
            }
            return false;
            
        }
        return false;
    }

    const nextVaccine = (vaccine) => {
        vaccine = (vaccine.name === "Sextuple" || vaccine.name === "Octuple") ? vaccine.filter((v) => v.name === "Sextuple" || v.name === "Octuple") : vaccine;
        if (vaccine.length > 0) {
            const lastVaccine = vaccine[0];
            const lastVaccineDate = lastVaccine.date;
            const nextVaccineDate = new Date(lastVaccineDate);
            nextVaccineDate.setFullYear(nextVaccineDate.getFullYear() + 1);
            return nextVaccineDate.toLocaleDateString();
        }
        return "";
    }

    const recommendedVaccines = (vaccine) => {
        const name = vaccine.name;
        if (pet?.species === "perro"){
            if (name === "Rabia" || name === "Sextuple" || name === "Octuple") {
                if (checkVaccine(name)) {
                    return "Vacuna al día";
                } else {
                    return "Vacuna atrasada";
                }
            }
            return "";
        } else if (pet?.species === "gato") {
            if (name === "Rabia" || name === "Triple felina") {
                if (checkVaccine(name)) {
                    return "Vacuna al día";
                } else {
                    return "Vacuna atrasada";
                }
            }
            return "";
        }
        return "";
    }
    

    useEffect(() => {
        let structureVaccines = [];
        if (pet?.species === "gato") {
            structureVaccines = [
                {
                    name: "Rabia",
                    dates: [],
                },
                {
                    name: "Triple felina",
                    dates: [],
                },
            ];
        } else if (pet?.species === "perro") {
            structureVaccines = [
                {
                    name: "Rabia",
                    dates: [],
                },
                {
                    name: "Sextuple",
                    dates: [],
                },
                {
                    name: "Octuple",
                    dates: [],
                },
            ];
        }
        for (let i = 0; i < vaccines.length; i++) {
            let vaccine = vaccines[i];
            //chequea si existe una vacuna con el mismo nombre
            let index = structureVaccines.findIndex((v) => v.name == vaccine.name);
            if (index === -1) {
                structureVaccines.push({
                    name: vaccine.name,
                    dates: [vaccine]
                });
            } else {
                structureVaccines[index].dates.push(vaccine);
            }
        }
        setStructureVaccines(structureVaccines);
        let showVaccines = [];
        for (let i = 0; i < structureVaccines.length; i++) {
            showVaccines.push(false);
        }
        setShowVaccines(showVaccines);
    }, [vaccines]);


    return (
        <div className="body-vacunas">
            {/* <h3>Vacunas</h3> */}
            {!openForm &&
                <button className="edit-button" onClick={() => setEdditing(!edditing)}>
                    {edditing ? "Terminar edición" : "Editar"}
                </button>
            }
            {!openForm && 
                <button className="añadir-button" onClick={() =>{setOpenForm(true); setEdditing(false)}}>
                    <FontAwesomeIcon icon={faPlus} />
                    Añadir registro
                </button>
            }
            {openForm && (
                <form className="form-añadir">
                    <h4>Añadir vacuna</h4>
                    {loading && <div className="load">
                        <ReactLoading type="spin" color="black" height={50} width={50} />
                    </div>}
                    <div className="close-div">
                        <button className="close-button" onClick={() => setOpenForm(false)}>
                            Cerrar 
                            <FontAwesomeIcon icon={faX} />
                        </button>
                    </div>
                    {newVaccine.error && (
                        <div className="vaccine-error">
                            <p>{newVaccine.error}</p>
                        </div>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha"
                            maxDate={dayjs(today)}
                            minDate={dayjs(pet?.birthdate) || dayjs("2000-01-01")}
                            value={newVaccine.date || null}
                            onChange={(newValue) => {
                                if (newVaccine.name === "Sextuple" || newVaccine.name === "Octuple" || newVaccine.name === "Rabia" || newVaccine.name === "Triple felina") {
                                    const reminder = new Date(newValue);
                                    reminder.setFullYear(reminder.getFullYear() + 1);
                                    setNewVaccine({...newVaccine, reminder: reminder, date: newValue});
                                } else {
                                    setNewVaccine({...newVaccine, date: newValue});
                                }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            className='date'
                        />
                    </LocalizationProvider>
                    
                    <Autocomplete
                    style={{marginBottom: 10, marginTop: 10}}
                        freeSolo
                        disablePortal
                        id='vaccines'
                        options={options}
                        renderInput={(params) => 
                            <TextField {...params} label="Nombre" />}
                        value={newVaccine.name}
                        onInputChange={(e, value) => {
                            if (value === "Sextuple" || value === "Octuple" || value === "Rabia" || value === "Triple felina") {
                                const reminder = new Date(newVaccine.date);
                                reminder.setFullYear(reminder.getFullYear() + 1);
                                setNewVaccine({...newVaccine, reminder: reminder, name: value});
                            } else {
                                setNewVaccine({...newVaccine, name: value});
                            }
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Recordatorio próxima vacuna (opcional)"
                            minDate={dayjs(new Date(newVaccine.date))}
                            value={newVaccine.reminder ? dayjs(newVaccine.reminder) : null}
                            onChange={(newValue) => setNewVaccine({...newVaccine, reminder: newValue})}
                            renderInput={(params) => <TextField {...params} />}
                            className='date'
                        />
                    </LocalizationProvider>


                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Duración de la vacuna"
                            minDate={dayjs(today)}
                        />
                    </LocalizationProvider> */}
                    <button className="añadir2-button" onClick={handleAddVaccine}>
                        Añadir
                    </button>
                </form>
            )}
            <div className="list-vacunas">
                {structureVaccines.map((vaccine, id) => (
                    <div className="vacuna" key={id}>
                        
                        <div className="nombre-vacuna">
                            <p><b>Vacuna:</b> {vaccine.name}</p>
                            {showVaccineRecommendation(vaccine) &&
                            <>
                            {/* <p>{nextVaccine(vaccine.dates)}</p> */}
                            <p
                                style={{
                                    color: checkVaccine(vaccine.name) ? "green" : "red",}}
                            
                            >{recommendedVaccines(vaccine)}</p>
                            </>
                            }
                        </div>

                        <div>
                        {(showVaccineRecommendation(vaccine)) && <p><b>Próxima vacuna: </b>{nextVaccine(vaccine.dates) || "Vacuna no registrada"}</p>}
                        {vaccine.dates.length > 0 && <p><b>Última vacuna:</b> {vaccine.dates[0].date}</p>}
                        </div>
                        <button className="show-button" onClick={() => {
                            let newShowVaccines = [...showVaccines];
                            newShowVaccines[id] = !showVaccines[id];
                            setShowVaccines(newShowVaccines);
                        }
                        }>
                            {showVaccines[id] ? "Ocultar" : "Mostrar"}
                        </button>
                        {showVaccines[id] &&
                        <div>
                            {vaccine.dates.map((date, index) => (
                                <div key={index} className="fecha-vacuna">
                                    <p><b>Fecha aplicación:</b> {date.date}</p>
                                    {(date.creator_id === userId && edditing) &&
                                    <button className="borrar-button" onClick={() => handleDeleteVaccine(date.id)}>
                                        Borrar
                                    </button>
                                    }
                                </div>
                            ))}
                        </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Vaccines;