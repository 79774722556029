import React, { useEffect, useState } from 'react';
import * as PetApi from "../../../api/apiPet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading';
import {TextField, Autocomplete } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch } from 'react-redux';
import { setPetData } from "../../../redux/actions/petActions.js";
import dayjs from 'dayjs';
import "./deworming.css"


const Deworming = ({ pet, userId, deworming, setDeworming }) => {
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newDeworming, setNewDeworming] = useState({
        date: "",
        name: "",
        type: "",
        reminder: "",
        error : ""
    });
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();

    const handleAddDeworming = async (e) => {
        e.preventDefault();
        // chequeamos que los campos date, name y type no estén vacíos ("")
        if (newDeworming.date === "")
            return setNewDeworming({ ...newDeworming, error: "La fecha no puede estar vacía" });
        if (newDeworming.name === "")
            return setNewDeworming({ ...newDeworming, error: "El nombre no puede estar vacío" });
        if (newDeworming.type === "")
            return setNewDeworming({ ...newDeworming, error: "El tipo no puede estar vacío" });
        setIsLoading(true);
        const response = await PetApi.addDewormingToPet(pet.id, newDeworming.date, newDeworming.name, newDeworming.type, userId, newDeworming.reminder);
        if (response) {
            setNewDeworming({
                date: "",
                name: "",
                type: "",
                reminder: "",
            });
            setOpenForm(false);
            reloadPets();
        } else {
            alert("Error al añadir el desparasitado");
        }
        setIsLoading(false);
    }

    const handleDeleteDeworming = async (dewormingId) => {
        const response = await PetApi.deleteDewormingFromPet(dewormingId, userId);
        if (response) {
            reloadPets();
        } else {
            alert("Error al borrar el desparasitado");
        }
    }

    // recargar la lista de mascotas
    const reloadPets = async () => {
        const pets = await PetApi.getPetsByOwner(userId);
        dispatch(setPetData(pets));
    }

    return (
        <div className="body-deworming">
            {!openForm &&
                <button className="edit-button" onClick={() => setIsEditing(!isEditing)}>
                    {isEditing ? "Terminar edición" : "Editar"}
                </button>
            }
            {!openForm ? (
                <button className="añadir-button" onClick={() => setOpenForm(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                    Añadir registro
                </button>
            ) : (
                <form className="form-añadir">
                    {isLoading && (
                        <div className="load">
                            <ReactLoading
                                type="spin"
                                color="black"
                                height={50}
                                width={50}
                            />
                        </div>
                    )}
                    {newDeworming.error && (
                        <div className="deworming-error">
                            <p>{newDeworming.error}</p>
                        </div>
                    )}
                    <div className="form-group">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Fecha"
                                value={newDeworming.date ? dayjs(new Date(newDeworming.date)) : null}
                                maxDate={dayjs(new Date())}
                                minDate={dayjs(pet?.birthdate) || dayjs("2000-01-01")}
                                onChange={(date) => setNewDeworming({ ...newDeworming, date: date.toDate() })}
                                renderInput={(params) => <TextField {...params} />}
                                className='date'
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="form-group">
                        <TextField
                            label="Nombre"
                            value={newDeworming.name}
                            onChange={(e) => setNewDeworming({ ...newDeworming, name: e.target.value })}
                            className='nombre'
                        />
                    </div>
                    <div className="form-group">
                        <Autocomplete
                        id='tipo'
                        options={["Interno", "Externo"]}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Tipo" />}
                        value={newDeworming.type}
                        onChange={(e, value) => setNewDeworming({ ...newDeworming, type: value })}
                        />
                    </div>
                    <div className="form-group">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Recordatorio para la próxima dosis (opcional)"
                                value={newDeworming.reminder ? dayjs(new Date(newDeworming.reminder)) : null}
                                minDate={dayjs(new Date(newDeworming.date))}
                                onChange={(date) => setNewDeworming({ ...newDeworming, reminder: date.toDate() })}
                                renderInput={(params) => <TextField {...params} />}
                                className='date'
                            />
                        </LocalizationProvider>
                    </div>
                    <button className="añadir-button" onClick={handleAddDeworming}>Añadir</button>
                </form>
            )}
            <div className="deworming-list">
                {deworming.map(deworming => (
                    <div key={deworming.id} className="deworming-item">
                        <p>Fecha: {deworming.date}</p>
                        <p>Nombre: {deworming.name}</p>
                        <p>Tipo: {deworming.type}</p>
                        {(isEditing && deworming.creator_id === userId) && (
                            <button className="edit-button" onClick={() => handleDeleteDeworming(deworming.id)}>
                                Borrar
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Deworming;