import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {  useSelector } from 'react-redux';
import Index from "./pages/index";
import Layout from "./pages/layout.js";
import Foros from "./pages/foros.tsx";
import ScrollToTop from "./components/scrollToTop.js";
import Mascotas from "./pages/mascotas.js";
import Perfil from "./pages/perfil.js";
/* import MiServicio from "./pages/miServicio.js"; */
/* import Colaboradores from "./pages/colaboradores.tsx"; */
/* import Servicios from "./pages/servicios.js"; */

/**
 * App component
 * contiene las rutas de la aplicación
 * @returns {JSX.Element} App component
 * */
function App(): JSX.Element {
  const user = useSelector((state: any) => state.user.userData );
  const [showLogin, setShowLogin] = useState(false);


  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout isLogged={user !== null && user !== undefined} user={user} showLogin={showLogin} setShowLogin={setShowLogin}/>}>
          <Route index element={<Index setShowLogin={setShowLogin}/>} />
          {/* <Route path="/servicios" element={<Servicios />} /> */}
          <Route path="/foros" element={<Foros />} />
          {/* <Route path="/colaboradores" element={<Colaboradores />} /> */}
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/mascotas" element={<Mascotas />} />
          <Route path="/mascotas/:id" element={<Mascotas />} />
          {/* <Route path="/miServicio" element={<MiServicio user={user} />} /> */}
        </Route>
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
