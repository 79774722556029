import { Outlet} from "react-router-dom";
import Navbar from "../components/navbar.tsx";
import Footer from "../components/footer";
import Login from "../components/login";

/**
 * Layout component
 * @description Este componente es el layout principal de la aplicación, el cual contiene el navbar, el footer y el login.
 */
const Layout = ({isLogged, user, showLogin, setShowLogin}) => {
    return(
        <>
            <Navbar 
                loginClick={setShowLogin}
                isLogged={isLogged}
                user={user}
            />
            <Login 
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
            <Outlet />
            <Footer />
        </>
    )
};

export default Layout;