import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MascotasItem from "../components/macotasItem";
import * as PetApi from "../api/apiPet";
import * as UserApi from "../api/apiUser";
import * as ForoApi from "../api/apiForum.js";
import * as analitycs from "../api/apiAnalytics.js";
import "./perfil.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faUser, faPlus} from "@fortawesome/free-solid-svg-icons";
import ForoItem from "../components/foros/foroItem.tsx";
import { useSelector, useDispatch } from "react-redux";
import { setPetData, clearPetData } from "../redux/actions/petActions.js";
import { clearUserData } from "../redux/actions/userActions.js";
import EditProfileModal from "../components/perfil/editProfileModal.tsx";
import { apiURL } from "../api/api.js";
import Reminders from "../components/perfil/reminders.tsx";


/**
 * Pagina que muestra el perfil de el usuario
 * 
 */
const Perfil = () => {
    const user = useSelector(state => state.user.userData);
    const pet = useSelector(state => state.pet.petData);
    const [foros, setForos] = useState([]);
    const [reminders, setReminders] = useState([]); 
    const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        if (!user) navigate("/");
    }, [user, navigate]);

    useEffect(()=>{
        if (user) {
            getPetsByUser(user.id);
            getForosByUser(user.id);
            getRemindersByUser(user.id);
        } 
    }, [user]);

    const logout = async () => {
        analitycs.sendLogoutEvent(user.id);
        const result = await UserApi.logoutUser();
        if(result){
          dispatch(clearUserData());
          dispatch(clearPetData());
          return true;
        } else {
          return false;
        }
    }

    const getPetsByUser = async (id) => {
        if (pet.length > 0) return;
        const pets = await PetApi.getPetsByOwner(id);
        dispatch(setPetData(pets));
    }

    const getForosByUser = async (id) => {
        let foros = await ForoApi.getForumsByUser(id);
        foros = foros.map((item) => ({
            ...item,
            created: item.created.toDate().toLocaleDateString(),
        }));
        setForos(foros);
    }

    const getRemindersByUser = async (id) => {
        let reminders = await UserApi.getRemindersByUser(id);
        reminders = reminders.map((item) => ({
            ...item,
        }));
        setReminders(reminders);
    }

    const changeEditProfileModalState = () => {
        setIsEditProfileModalOpen(!isEditProfileModalOpen);
    }

    return (
        <>
            <section 
                className="perfil-container"
                style={{
                    textAlign: "center",
                    maxWidth: 1000,
                    margin: "auto",
            }}>
            <h2>Perfil</h2>
            <div className="perfil">
                <figure className="perfil-photo">
                    {(user?.photo === "" || user?.photo === null || user?.photo === undefined) ? (
                        <FontAwesomeIcon
                            icon={faUser}
                            style={{
                                fontSize: 200,
                                color: "black"
                            }}
                        />
                    ) :
                    <img src={apiURL+"/media/"+user?.photo} alt={user?.username} 
                    style={{
                        width: 200,
                        height: 200,
                        borderRadius: "50%",
                    }}/>
                    }
                </figure>
                <div className="perfil-info">
                    <p>Nombre de usuario: {user?.name}</p>
                    <p>Correo: {user?.email}</p>
                    <p>Teléfono: {user?.phone}</p>
                    <div className="perfil-buttons">
                        <button style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            border: "none",
                            backgroundColor: "rgba(232, 222, 201, 0.5)",
                            cursor: "pointer",
                            width: 100,
                            height: 50,
                            borderRadius: 20,
                        }}
                            onClick={()=>{
                                changeEditProfileModalState();
                            }}
                        >
                            <p>Editar</p>
                            <FontAwesomeIcon
                                icon={faPen}
                                style={{
                                    fontSize: 10,
                                    color: "black"
                                }}
                            />
                        </button>
                        <button 
                            style={{
                                border: "none",
                                backgroundColor: "rgba(232, 222, 201, 0.5)",
                                cursor: "pointer",
                                width: 100,
                                height: 50,
                                borderRadius: 20,
                            }}
                            onClick={()=>{
                                logout();
                                navigate("/");
                            }}
                        >Cerrar sesión</button>
                    </div>
                </div>
            </div>
            <EditProfileModal user={user} pets={pet} open={isEditProfileModalOpen} onClose={changeEditProfileModalState}/>
            </section>
            {user?.userType === "Colaborador" ? (
                <section style={{
                    textAlign: "center",
                    maxWidth: 1000,
                    margin: "auto",
                }}>
                    <h2>Mi Servicio</h2>

                </section>
            ):(
            <section style={{
                textAlign: "center",
                maxWidth: 1000,
                margin: "auto",
            }}>
            <h2>Mascotas</h2>
            <div className="mascotas">
                {pet.map(pet => (
                    <Link key={pet.id} to={`/mascotas/${pet.id}`}>
                        <MascotasItem  mascota={pet} />
                    </Link>
                ))}
                <button
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "10px",
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        width: 75,
                    }}
                    onClick={()=>{
                        navigate("/mascotas/nueva");
                }}>
                    <div style={{
                        backgroundColor: "rgba(232, 222, 201, 0.5)",
                        borderRadius: "50%",
                        height: 100,
                        width: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                                fontSize: 50,
                                color: "black"
                            }}
                        />
                    </div>
                    <h4 style={{
                        color: "black",
                        textDecoration: "none",
                    }} >Nueva mascota</h4>
                </button>

            </div>
            </section>
            )}
            <Reminders reminders={reminders} pets={pet} UserId={user.id} getReminder={()=>{getRemindersByUser(user.id)}}/>
            <section style={{
                textAlign: "center",
                maxWidth: 1000,
                margin: "auto",
            }}>
                <h2>Mis Foros</h2>
                <div className="foros">
                    {foros.map(foro => (
                        <ForoItem key={foro.id} foro={foro} onclick={()=>{navigate(`/foros`)}} userID={user.id}/>
                    ))}
                </div>
            </section>
        </>
    );
}

export default Perfil;