import { db } from './api';
import { doc, setDoc, Timestamp, } from "firebase/firestore";
import { apiURL } from './api';

export const getForums = async () => {
    try {
        const response = await fetch(apiURL+"/forums/")
        if(response.ok){
            const data = await response.json();
            data.forEach((foro) => {
                foro.created = Timestamp.fromDate(new Date(foro.created_at));
                foro.updated_at = new Date(foro.updated_at);
                foro.created_at = new Date(foro.created_at);
            });
            return data;
        } else{
            console.error("Error al obtener los foros");
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * 
 * @param {string} title titulo del foro
 * @param {string} description contenido del foro
 * @param {string} autorId id del autor
 * @returns 
 */
export const createForum = async (title, description, autorId) => {
    try {
        const response = await fetch(apiURL+"/forums/create/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title: title,
                description: description,
                author: autorId
            })
        });
        if(response.ok){
            return true;
        } else{
            console.error("Error al crear el foro");
            return false;
        }
    }   catch (error) {
        console.error(error);
        return false;
    }
}


export const getComments = async (foroId) => {
    try {
        const response = await fetch(apiURL+"/forums/" + foroId+ "/comments/");
        if(response.ok){
            const data = await response.json();
            return data;
        } else{
            console.error("Error al obtener los comentarios");
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * función para crear un comentario
 * @param {string} foroId id del foro
 * @param {string} comentario comentario
 * @param {string} autorId id del autor
 * @returns 
 */
export const createComments = async (foroId, comentario, autorId) => {
    try {
        const response = await fetch(apiURL+'/forums/comments/create/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                forumId: foroId,
                content: comentario,
                author: autorId
            })
        });
        if (response.ok) {
            const data = await response.json();
            return data.success;
        } else {
            console.error('Failed to create comment:', response.statusText);
            return false;
        }
    } catch (error) {
      console.error("Error creating comment: ", error);
      return false;
    }
};


/**
 * función para dar like a un foro
 * @param {string} userId id del usuario
 * @param {string} forumId id del foro
 * @returns 
 */
export const giveLikeToForum = async (userId, forumId) => {
    try {
        const response = await fetch(apiURL+'/forum/like/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: userId,
                forumId: forumId
            })
        });
        if (response.ok) {
            const data = await response.json();
            return data.success;
        } else {
            console.error('Failed to give like to forum:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error("Error giving like to forum: ", error);
        return false;
    }
};

export const removeLikeToForum = async (userId, forumId) => {
    try {
        const response = await fetch(apiURL+'/forum/unlike/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: userId,
                forumId: forumId
            })
        });
        if (response.ok) {
            const data = await response.json();
            return data.success;
        } else {
            console.error('Failed to give like to forum:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error("Error giving like to forum: ", error);
        return false;
    }
};


/**
 * función para obtener los foros de un usuario
 * @param {string} userId id del usuario
 * @returns 
 */
export const getForumsByUser = async (userId) => {
    try {
        const response = await fetch(apiURL+"/forums/user/" + userId+ "/");
        if(response.ok){
            const data = await response.json();
            data.forEach((foro) => {
                foro.created = Timestamp.fromDate(new Date(foro.created_at));
                foro.updated_at = new Date(foro.updated_at);
                foro.created_at = new Date(foro.created_at);
            });
            return data;
        } else{
            console.error("Error al obtener los foros");
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

/**
 * Función para agregar una foto a un foro
 * @param {string} foroId id del foro
 * @param {string} photo url de la foto
 * @returns 
 */
export const updateForoPhoto = async (foroId, photo) => {
    try {
        const petRef = doc(db, "forum", foroId);
        await setDoc(petRef, {photo: photo}, {merge: true});
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}