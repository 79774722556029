import React from "react";
import * as UserApi from "../../api/apiUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyringe, faPills, faPrescriptionBottle, faPlus, faPen, faHouseMedical } from '@fortawesome/free-solid-svg-icons';
import {TextField, Autocomplete } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ReactLoading from "react-loading";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface ReminderType {
    id: number,
    type: string,
    description: string,
    date: string,
    pet_name: string,
    medicines: Array<MedicineType>,
}
interface MedicineType {
    id: number,
    name: string,
    dose: string,
    frequency: string,
    days: number,
    hours: Array<string>,
}
interface petsType {
    id: number,
    name: string,
    birthdate: string,
    breed: string,
    weight: number,
}


const Reminders = ({reminders, pets, UserId, getReminder}: {reminders: Array<ReminderType>, pets: Array<petsType>, UserId: number, getReminder: Function}) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [reminder, setReminder] = React.useState<ReminderType>({
        id: 0,
        type: "",
        description: "",
        date: "",
        pet_name: "",
        medicines: [],
    });
    const [reminderPetId, setReminderPetId] = React.useState<Number>(0);
    const [medicine, setMedicine] = React.useState<MedicineType>({
        id: 0,
        name: "",
        dose: "",
        frequency: "",
        days: 0,
        hours: [],
    });

    const handleAddReminder = async (event: React.FormEvent) => {
        event.preventDefault();
        if (reminder.type === "" || reminder.description === "" || reminder.date === "" || reminderPetId === 0) {
            alert("Por favor llena todos los campos");
            return;
        }
        setIsLoading(true);
        const res = await UserApi.addReminder(UserId, reminder, reminderPetId);
        setIsLoading(false);
        if (res) {
            setIsAdding(false);
            setReminder({
                id: 0,
                type: "",
                description: "",
                date: "",
                pet_name: "",
                medicines: [],
            });
            setMedicine({
                id: 0,
                name: "",
                dose: "",
                frequency: "",
                days: 0,
                hours: [],
            });
            getReminder();
        }
    }
    const handleDeleteReminder = async (id: number) => {
        const res = await UserApi.deleteReminder(id);
        if (res) {
            getReminder();
        }
    }
    return (
        <section style={{
            textAlign: "center",
            maxWidth: 1000,
            margin: "auto",
            position: "relative",
        }}>
            <h2 style={{marginBottom: 50}}>Recordatorios</h2>
            {! isAdding && 
                <button className="edit-button" onClick={() => setIsEditing(!isEditing)} style={{
                    top: "40px",
                }}>
                    <FontAwesomeIcon icon={faPen} style={{marginRight: 10}} />
                    {isEditing ? "Cancelar" : "Editar recordatorios"}
                </button>
            }
            {!isAdding ?
            <button className="añadir-button" onClick={() => setIsAdding(true)} >
                <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}} />
                Agregar recordatorio
            </button> :
            (
                <form className="form-añadir">
                    {isLoading && (
                        <div className="load">
                            <ReactLoading
                                type="spin"
                                color="black"
                                height={50}
                                width={50}
                            />
                        </div>
                    )}
                    <h3>Agregar recordatorio</h3>
                    <Autocomplete
                        options={[{title: "Vacuna", value: "vaccine"},{title: "Desparasitante", value: "dewormer"}, {title: "Cita veterinaria", value: "appointment"}]}
                        getOptionLabel={(option) => option.title}
                        style={{margin: 10}}
                        renderInput={(params) => <TextField {...params} label="Tipo" />}
                        onChange={(event, value) => {if (value) setReminder({...reminder, type: value.value})}}
                    />
                    <Autocomplete
                        options={pets}
                        getOptionLabel={(option) => option.name}
                        style={{margin: 10}}
                        renderInput={(params) => <TextField {...params} label="Mascota" />}
                        onChange={(event, value) => {if (value) setReminderPetId(value.id)}}
                    />
                    
                    <TextField
                        label="Descripción"
                        style={{margin: 10}}
                        onChange={(event) => setReminder({...reminder, description: event.target.value})}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha"
                            minDate={dayjs(new Date())}
                            value={reminder.date ? dayjs(reminder.date) : null}
                            onChange={(date) => {if (date) setReminder({...reminder, date: date.toISOString()})}}
                        />
                    </LocalizationProvider>
                    {(reminder.type === "medicine" && reminder.medicines.length > 0) && (
                        <div>
                            <h4>Medicamentos</h4>
                            {reminder.medicines.map((medicine) => (
                                <div key={medicine.id}>
                                    <p>{medicine.name}: {medicine.dose}, {medicine.frequency} veces al dia, por {medicine.days} días</p>
                                    <button className="eliminar-button" onClick={(e) => {
                                        e.preventDefault();
                                        setReminder({...reminder, medicines: reminder.medicines.filter(m => m.id !== medicine.id)});
                                    }}>Eliminar</button>
                                </div>
                            ))}
                        </div>
                    )}


                    {reminder.type === "medicine" && (
                        <>
                        <TextField
                            label="Nombre del medicamento"
                            style={{margin: 10}}
                            value={medicine.name}
                            onChange={(event) => setMedicine({...medicine, name: event.target.value})}
                        />
                        <TextField
                            label="Dosis"
                            style={{margin: 10}}
                            value={medicine.dose}
                            onChange={(event) => setMedicine({...medicine, dose: event.target.value})}
                        />
                        <TextField
                            label="Frecuencia"
                            type="number"
                            style={{margin: 10}}
                            value={medicine.frequency}
                            onChange={(event) => setMedicine({...medicine, frequency: event.target.value})}
                        />
                        <TextField
                            label="Días de tratamiento"
                            type="number"
                            style={{margin: 10}}
                            value={medicine.days}
                            onChange={(event) => setMedicine({...medicine, days: parseInt(event.target.value)})}
                        />
                        <button className="añadir-button" onClick={(e) => {
                            e.preventDefault();
                            if (medicine.name === "" || medicine.dose === "" || medicine.frequency === "" || medicine.days === 0) {
                                alert("Por favor llena todos los campos");
                                return;
                            }
                            setReminder({...reminder, medicines: [...reminder.medicines, medicine]});
                            setMedicine({
                                id: Math.max(...reminder.medicines.map(m => m.id)) + 1,
                                name: "",
                                dose: "",
                                frequency: "",
                                days: 0,
                                hours: [],
                            });
                            }}>
                            Agregar medicamento
                        </button>
                        </>
                    )}


                    <button className="añadir-button" onClick={handleAddReminder}>
                        Agregar recordatorio
                    </button>
                </form>
            )}

            {reminders.length === 0 && 
            <p style={{
                color: "gray",
                fontSize: 20,
                height: "30vh"
            }}
            >No hay recordatorios</p>}
            {reminders.map(reminder => (
                <div key={reminder.id} className="recordatorio" style={{
                    display: "grid",
                    gridTemplateColumns: !isEditing ? "50px 1fr" : "1fr",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(232, 222, 201, 0.5)",
                    borderRadius: 20,
                    margin: 10,
                    padding: 10,
                    position: "relative",
                }}>
                    {(reminder.type === "vaccine" || reminder.type === "medicine" || reminder.type === "dewormer" || reminder.type === "appointment") && (
                        <FontAwesomeIcon
                            icon={reminder.type === "vaccine" ? faSyringe : reminder.type === "medicine" ? faPills : reminder.type === "dewormer" ? faPrescriptionBottle : faHouseMedical}
                            style={{
                                fontSize: 30,
                                color: "black",
                                marginRight: 10,
                            }}
                        />
                    )}
                    <div 
                        style={{
                            
                        }}>
                        <h3>Recordatorio: {reminder.description}</h3>
                        <p>Mascota: {reminder.pet_name}</p>
                        <p>Fecha:{new Date(reminder.date).toLocaleDateString()}</p>
                        {reminder.type === "vaccine" && (
                            <p><small>
                                Quedan {Math.ceil((new Date(reminder.date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} días
                            </small></p>
                            )}
                        {reminder.type === "medicine" && (
                            <>
                            <h4>Medicamentos</h4>
                            {reminder.medicines && reminder.medicines.map((medicine) => (
                                <>
                                <p key={medicine.id}>{medicine.name}: {medicine.dose}, {medicine.frequency} veces al dia, por {medicine.days} días</p>
                                {/* {medicine.hours.map((hour, id) => {
                                    return <p key={id}>Hora: {hour}</p>
                                })} */}
                                </>
                            ))}
                            </>
                        )}
                        {isEditing && (
                            <button className="edit-button" onClick={() => handleDeleteReminder(reminder.id)}>
                                Borrar
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </section>
    )
}


export default Reminders;