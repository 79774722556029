import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faXTwitter, faYoutube, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./footer.css";

/**
 * Footer Component
 * componente que muestra el footer de la pagina
 */
const Footer = () => {
    return (
        <footer style={{backgroundColor: "#FFBD59", margin: 0, display: "flex", justifyContent: "space-evenly"}}>
            <div>
                <p>Contactos:</p>
                <p>Correo: contacto@pawzone.cl </p>
                <p>Teléfono: +56 9 4483 2972</p>
            </div>
            <div>
                <p>Redes Sociales:</p>
                <a href="https://www.facebook.com/people/PawZone/61561016671764/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon
                        icon={faFacebook} 
                        size="2x" 
                        color="#382A15"
                    />
                </a>
                <a href="https://www.instagram.com/pawzone.cl/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon 
                        icon={faInstagram} 
                        size="2x"
                        color="#382A15" 
                    />
                </a>
                <a href="https://api.whatsapp.com/send?phone=%2B56944832972&context=ARCy_mForVsBeZaAhGnWtFEhjyroxs1UzErMKtps7VdlHu8jo1nolaQjfUfgk-ZfdwTWvSTrOgnxhHrw80B8eZuJ8-EJT8119xGhzelMXeuruNVxXjb7vhI1gcbLzywDN0HZV0BswurRpqXCuIg9B9gZ4A&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwZXh0bgNhZW0CMTAAAR0Pe-1zADIQgW2lZ5PJ4S3DgGhMPMjXk_kFT8QafW_O_VUfK7OfHmzXVJ4_aem_ZmFrZWR1bW15MTZieXRlcw" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon
                        icon={faWhatsapp}
                        size="2x"
                        color="#382A15"
                    />
                </a>
                {/* <FontAwesomeIcon 
                    icon={faXTwitter} 
                    size="2x"
                    color="#382A15" 
                />
                <FontAwesomeIcon 
                    icon={faYoutube} 
                    size="2x"
                    color="#382A15" 
                />
                <FontAwesomeIcon 
                    icon={faLinkedin} 
                    size="2x"
                    color="#382A15" 
                /> */}
            </div>
        </footer>
    );
};

export default Footer;
