import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import * as Api from "../../api/api";
import * as UserApi from "../../api/apiUser";
import * as PetApi from "../../api/apiPet";
// import * as PhotosApi from "../../api/apiPhotos";
import Vaccines from './ficha/vaccine';
import VetHistory from './ficha/vetHistory';
import { useDispatch } from 'react-redux';
import { setPetData } from "../../redux/actions/petActions.js";
import "./mascotaDetails.css"
import Deworming from './ficha/deworming.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faShare } from '@fortawesome/free-solid-svg-icons';
// import { Modal } from '@mui/material';

/**
 * MascotaDetails component
 * @description Componente que muestra los detalles de una mascota
 * @param {object} mascota - objeto con la información de la mascota
 * @module MascotaDetails
 */
const MascotaDetails = ({ mascota, userId, medicines}) => {
    // mascota del componente
    const [pet, setPet] = useState(null);
    // historial médico de la mascota
    const [medicalHistory, setMedicalHistory] = useState([]);
    // vacunas de la mascota
    const [vaccines, setVaccines] = useState([]);
    // desparasitados de la mascota
    const [deworming, setDeworming] = useState([]);
    const [photo, setPhoto] = useState(null);
    const [peso, setPeso] = useState("");
    const [microchip, setMicrochip] = useState("");
    const [castrated, setCastrated] = useState("");
    const [alergia, setAlergia] = useState("");
    const [enfermedades, setEnfermedades] = useState("");
    const [editPhoto, setEditPhoto] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState("vetHistory");
    const [editando, setEditando] = useState(false);
    const [sharing, setSharing] = useState(false);
    const [shareUser, setShareUser] = useState("");
    const [shareUserList, setShareUserList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setPet(mascota);
        setPhoto(null);
        setEditPhoto(false);
        if (mascota === undefined || mascota === null) {
            return;
        }
        if (mascota.id) {
            getMedicalHistoryByPet(mascota.id);
            getVaccinesByPet(mascota.id);
            getDewormingByPet(mascota.id);
        }
        setPeso(mascota?.weight);
        setMicrochip(mascota?.chip);
        setCastrated(mascota?.castrated);
    }, [mascota]);

    useEffect(() => {
        ReactModal.setAppElement('body');
    }, []);

    // recargar la lista de mascotas
    const reloadPets = async () => {
        const pets = await PetApi.getPetsByOwner(userId);
        dispatch(setPetData(pets));
    }

    const getVaccinesByPet = async (id) => {
        const response = await PetApi.getVaccinesByPet(id);
        setVaccines(response);
    }

    const getMedicalHistoryByPet = async (id) => {
        const response = await PetApi.getMedicalHistoryByPet(id);
        setMedicalHistory(response);
    }

    const getDewormingByPet = async (id) => {
        const response = await PetApi.getDewormingByPet(id);
        setDeworming(response);
    }

    // Función que calcula la edad de la mascota
    function calculateAge(birthDateString) {
        const birthDate = new Date(birthDateString);
        const currentDate = new Date();
        let years = currentDate.getFullYear() - birthDate.getFullYear();
        let months = currentDate.getMonth() - birthDate.getMonth();
        if (months < 0) {
            years -= 1
            months += 12
        }
        return [years, months];
    }
    // Función que calcula los días que faltan para el cumpleaños de la mascota
    function calculateDaysToBirthday(birthDateString) {
        const birthDate = new Date(birthDateString);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const birthDateThisYear = new Date(currentYear, birthDate.getMonth(), birthDate.getDate());
        if (currentDate > birthDateThisYear) {
            const nextBirthDate = new Date(currentYear + 1, birthDate.getMonth(), birthDate.getDate());
            return Math.ceil((nextBirthDate - currentDate) / (1000 * 60 * 60 * 24));
        } else {
            return Math.ceil((birthDateThisYear - currentDate) / (1000 * 60 * 60 * 24));
        }
    }

    const searchUser = async (e) => {
        e.preventDefault();
        const user = await UserApi.searchUsers(shareUser);
        if (user) {
            setShareUserList(user);
        }
        console.log(user);
    }

    
    return (
        <section className='shadow'>
            {(pet === null || pet?.id === "") &&
                <h2>Selecciona una mascota para ver sus detalles</h2>
            }
            {(pet !== null && pet?.id !== "") &&
            <div className="detalles-mascota">
            <ReactModal isOpen={sharing} onRequestClose={() => {setSharing(false)}} className='sharing' 
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 100,
                },
            }}>
                <div style={{backgroundColor: "white", width: "90%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: 25, maxWidth: 600, zIndex: 100, maxHeight: "90vh", overflowY: "auto"}}>
                <div style={{backgroundColor: "rgba(255, 248, 233, 0.5)", textAlign: "center", borderRadius: 25, fontSize: "larger", padding: 40}} className="login">
                    <p>¿Con quien quieres compartir a <b>{pet?.name}</b>?</p>
                    <form>
                        <label style={{width: 'max-content'}}>Nombre de usuario</label>
                        <input type='text' placeholder='Nombre de usuario' style={{borderRadius: 30, backgroundColor: 'transparent', paddingLeft: 20}} onChange={(e) => setShareUser(e.target.value)}
                        value={shareUser}
                        ></input>
                        <button
                            onClick={searchUser}
                            style={{borderRadius: 30, backgroundColor: 'transparent', border: '1px solid black', padding: 10, margin: 10}}
                        >
                            Buscar
                        </button>
                    </form>
                    <div>
                        {shareUserList && shareUserList.map((user) => {
                            return <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                <p>{user.name}</p>
                                <button onClick={async () => {
                                    const res = await PetApi.sharePet(pet.id, user.id);
                                    if (res) {
                                        setSharing(false);
                                    }
                                }}>Compartir</button>
                            </div>
                        })}
                    </div>
                    <p>Al compartir el perfil de tu mascota, solo estás dando permiso para visualizar la información de tu mascota. Esto puede ser útil para tu grupo familiar, amigos cercanos e incluso a tu veterinari@.</p>
                </div>
                </div>
            </ReactModal>
                <div className="mascota-info">
                    <div className="mascota-info-subset">
                        <div>
                            {!editPhoto ?
                            <div className="mascota-foto">
                            <button className='share' onClick={() => {setSharing(true); console.log(sharing)}}><FontAwesomeIcon icon={faShare} style={{fontSize: 25}}/></button>
                            <img src={Api.apiURL+"/media/"+ pet?.photo} alt={pet?.name}/>
                            <h2>{pet?.name}</h2>
                            <button className="cambiar-foto" onClick={() => setEditPhoto(true)}>Cambiar foto</button>
                            </div> :
                            <>
                            <form
                                onSubmit={async (e) => {
                                    e.preventDefault();
                                    if (photo === null) {
                                        return;
                                    }
                                    setLoading(true);
                                    const res = await PetApi.updatePetPhoto(pet.id, photo);
                                    setLoading(false);
                                    if (res) {
                                        setEditPhoto(false);
                                        setPhoto(null);
                                        reloadPets();
                                    }
                                }}
                            >   
                                <div className='mascota-foto'>
                                    <img src={photo ? URL.createObjectURL(photo) : null} alt={"Previsualización de imagen"} className="foto-mascota-img"/>
                                </div>
                                <label htmlFor="photo" className='cambiar-foto-label'>
                                    Elegir foto
                                </label>
                                <input type="file" name="photo" id="photo" onChange={(e) => setPhoto(e.target.files[0])} style={{display: "none"}} />
                                <button type="submit" disabled={loading} className='cambiar-foto-button'>Guardar nueva foto</button>
                                <button onClick={() => setEditPhoto(false)} disabled={loading} className='cambiar-foto-button' >Cancelar</button>
                            </form>
                            </>
                            }
                        </div>
                        <div className="antecedentes-generales">
                            <h4>Antecedentes generales</h4>
                            <p>
                                {editando ? 
                                <form 
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        if (peso === "") {
                                            return;
                                        };
                                        setLoading(true);
                                        const res = await PetApi.updatePet(
                                            pet.id,
                                            pet.name,
                                            pet.species,
                                            pet.breed,
                                            pet.birthdate,
                                            peso,
                                            pet.color,
                                            pet.sex,
                                            pet.chip,
                                            pet.castrated,
                                            pet.allergies,
                                            pet.cronic_diseases,
                                            pet.description);
                                        setLoading(false);
                                        if (res) {
                                            console.log("Eeeeeeee")
                                            setEditando(false);
                                            setPeso("");
                                            reloadPets();
                                        };
                                    }}>
                                    <div>
                                        Peso:
                                        <label htmlFor="peso"></label>
                                        <input type="number" name="peso" id="peso" value={peso} onChange={(e) => {setPeso(e.target.value)}}></input>
                                        <button type="submit" className="edit-antecedentes-button"><FontAwesomeIcon icon={faCheck} /></button>
                                    </div>
                                </form> 
                                : `Peso: ${pet?.weight} Kg`}
                            </p>
                            <p>
                                {editando ? 
                                <p> Fecha de nacimiento: {new Date(pet?.birthdate).toLocaleDateString()}</p>
                                : 
                                <div>
                                    Edad: {calculateAge(pet?.birthdate)[0]} años y {calculateAge(pet?.birthdate)[1]} meses <br/>
                                    <span className="dias-cumpleaños">({calculateDaysToBirthday(pet?.birthdate)} días para su cumpleaños)</span>
                                </div>
                                }
                            </p>
                            <p>
                                {editando ? 
                                <form onSubmit={ async (e) => {
                                    e.preventDefault();
                                    if (microchip === "") {
                                        return;
                                    };
                                    const res = await PetApi.updatePet(
                                        pet.id,
                                        pet.name,
                                        pet.species,
                                        pet.breed,
                                        pet.birthdate,
                                        pet.weight,
                                        pet.color,
                                        pet.sex,
                                        microchip,
                                        pet.castrated,
                                        pet.allergies,
                                        pet.cronic_diseases,
                                        pet.description);
                                    if (res) {
                                        console.log(microchip)
                                        setEditando(false);
                                        setMicrochip("");
                                        reloadPets();
                                    }
                                }}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                    <label htmlFor="Microchip"> Microchip: </label>
                                    <input type='text' value={microchip} onChange={(e) => setMicrochip(e.target.value)}></input>
                                    </div>
                                    <button type="submit" className="edit-antecedentes-button"><FontAwesomeIcon icon={faCheck} /></button>  
                                    </div>
                                </form> 
                                : `Microchip: ${(pet?.chip === null || pet?.chip === "" || pet?.chip === undefined) ? "No tiene" : pet?.chip}`}
                            </p>
                            <p> Esterilizado:
                            {editando ? 
                                <form onSubmit={ async (e) => {
                                    e.preventDefault();
                                    if (castrated === "") {
                                        return;
                                    };
                                    const res = await PetApi.updatePet(
                                        pet.id,
                                        pet.name,
                                        pet.species,
                                        pet.breed,
                                        pet.birthdate,
                                        pet.weight,
                                        pet.color,
                                        pet.sex,
                                        pet.chip,
                                        castrated,
                                        pet.allergies,
                                        pet.cronic_diseases,
                                        pet.description);
                                    if (res) {
                                        setEditando(false);
                                        setCastrated("");
                                        reloadPets();
                                    };
                                }}>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                    <label htmlFor="SiCastrated">
                                        Si está esterilizado
                                        <input type="radio" name="castrated?" id="SiCastrated" onChange={() => setCastrated(true)} checked={castrated}></input>
                                    </label>
                                    <label htmlFor="NoCastrated">
                                        No está esterilizado
                                        <input type="radio" name="castrated?" id="NoCastrated" onChange={() => setCastrated(false)} checked={!castrated}></input>
                                    </label>
                                    </div>
                                    <button type="submit" className="edit-antecedentes-button"> <FontAwesomeIcon icon={faCheck} /></button>
                                    </div>
                                </form> 
                                : (pet?.castrated && " Si está esterilizado") || (!pet?.castrated && " No está esterilizado")}
                            </p>
                        </div>
                        <div className="division"></div>
                        <div className="antecedentes-salud">
                            <h4>Antecedentes de salud</h4>
                            <p>Fecha proxima vacuna:</p>
                            <p>
                                Alergias:
                                {editando ?
                                <>
                                <form onSubmit={ async (e) => {
                                    e.preventDefault();                                    
                                    const res = await PetApi.addAllergiesToPet(pet.id, alergia);
                                    if (res) {
                                        setEditando(false);
                                        setAlergia("");
                                        reloadPets();
                                    }
                                }}>
                                    <div>
                                    <label htmlFor="alergias"></label>
                                    <input type="text" value={alergia} onChange={(e) => setAlergia(e.target.value)}></input>
                                    <button type="submit" className="edit-antecedentes-button"> <FontAwesomeIcon icon={faCheck} /></button>
                                    </div>
                                </form> 
                                <div>
                                    {pet?.allergies && pet?.allergies.map((alergia) => {
                                        return <ul><li><div id={alergia.id}>{alergia.name} <button className='delete-allergy-button' onClick={async () => {
                                            const res = await PetApi.deleteAllergyFromPet(alergia.id);
                                            if (res) {
                                                reloadPets();
                                            }
                                        }}>X</button></div></li></ul>
                                    }
                                    )}
                                </div>
                                </>
                                : <>
                                    {pet?.allergies && pet?.allergies.map((alergia) => {
                                        return <ul className='alergia'> <li id={alergia.id}>{alergia.name}</li></ul>

                                    })}
                                </> }
                            </p>
                            <p>
                                Enfermedades cronicas:
                                {editando ? 
                                <>
                                <form onSubmit={ async (e) => {
                                    e.preventDefault();
                                    const res = await PetApi.addCronicalDiseasesToPet(pet.id, enfermedades);
                                    if (res) {
                                        setEditando(false);
                                        setEnfermedades("");
                                        reloadPets();
                                    }
                                }}>
                                    <div>
                                    <label htmlFor="enfermedades"></label>
                                    <input type="text" value={enfermedades} onChange={(e) => setEnfermedades(e.target.value)}></input>
                                    <button type="submit" className="edit-antecedentes-button"> <FontAwesomeIcon icon={faCheck} /></button>
                                    </div>
                                </form> 
                                <div>
                                    {pet?.cronic_diseases && pet?.cronic_diseases.map((enfermedad) => {
                                        return <ul><li><div id={enfermedad.id}>{enfermedad.name} <button className='delete-disease-button' onClick={async () => {
                                            const res = await PetApi.deleteCronicalDiseaseFromPet(enfermedad.id);
                                            if (res) {
                                                reloadPets();
                                            }
                                        }}>X</button></div></li></ul>
                                    }
                                    )}
                                </div>
                                </>
                                : <><br/> {pet?.cronic_diseases && pet?.cronic_diseases.map((enfermedad) => {
                                    return <ul><li><p id={enfermedad.id}>{enfermedad.name}</p></li></ul>
                                }
                                )}</> }
                            </p>
                        </div>
                    </div>
                    {editando ? <button className="editar-button" onClick={() => setEditando(false)} style={{fontSize: "smaller"}}>Terminar Edición</button> : <button className='editar-button' onClick={() => setEditando(true)}>Editar</button>}
                </div>
                <div className="navBar">
                    <button className="consulta-button" style={(state === "vetHistory") ? {border: "3px solid #000", fontWeight: 'bolder', fontSize: 'medium'} : {}} 
                    onClick={() => {console.log("consulta"); setState("vetHistory")}}>
                        Consulta
                    </button>
                    
                    <button className="antiparasitario-button" style={(state === "deworming") ? {border: "3px solid #000", fontWeight: 'bolder', fontSize: 'medium'} : {}}
                    onClick={() => {console.log("antiparasitario"); setState("deworming")}}>
                        Antiparasitario
                    </button>
                    
                    <button className="vacuna-button" style={(state === "vaccines") ? {border: "3px solid #000", fontWeight: 'bolder', fontSize: 'medium'} : {}} onClick={() => {console.log("vacuna"); setState("vaccines")}}>
                        Vacuna
                    </button>
                </div>
                {(state === "vaccines") && <Vaccines vaccines={vaccines} pet={pet} userId={userId} setVaccines={setVaccines}/>}
                {(state === "vetHistory") && <VetHistory pet={pet} userId={userId} medicalHistory={medicalHistory} setMedicalHistory={setMedicalHistory} medicines={medicines}/>}
                {(state === "deworming") && <Deworming pet={pet} userId={userId} deworming={deworming} setDeworming={setDeworming}/>}
            </div>
            }
        </section>
    );
}

export default MascotaDetails;