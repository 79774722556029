const initialState = {
    foroData: [],
    update: null,
};


const foroReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FORO_DATA':
            return {
                ...state,
                foroData: action.payload,
                update: new Date().toISOString(),
            };
        case 'CLEAR_FORO_DATA':
            return {
                ...state,
                foroData: [],
                update: new Date().toISOString(),
            };

        case 'ADD_FORO_DATA':
            return {
                ...state,
                foroData: [...state.foroData, action.payload],
            };
        case 'SET_MSG_TO_FORO':
            return {
                ...state,
                foroData: state.foroData.map((item) => {
                    if (item.id === action.payload.id) {
                        item.msg = action.payload.msg;
                        item.updated = new Date().toISOString();
                    }
                    return item;
                }),
            };
        default:
            return state;
    }
}

export default foroReducer;