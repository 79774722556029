import { useEffect, useState } from "react";
import Modal from "react-modal";
import * as UserAPI from "../api/apiUser.js";
import ReactLoading from 'react-loading';
import { useDispatch} from "react-redux";
import { setUserData } from "../redux/actions/userActions.js";
import * as analytics from "../api/apiAnalytics.js";
import "./login.css";

/**
 * Login component
 * component to display a login form and register form in a modal
 */

const Login = ({showLogin, setShowLogin}) => {
    const dispatch = useDispatch();
    const [userType, setUserType] = useState("Pet lover");
    const [showState, setShowState] = useState("Login");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    useEffect(() => {
        setEmail("");
        setName("");
        setPassword("");
        setConfirmPassword("");
        setError("");
    }, [showState]);


    const login = async(email, password) => {
        const USER = await UserAPI.loginUser(email, password);
        if(USER !== null){
            dispatch(setUserData(USER));
            analytics.sendLoginEvent(USER.id);
            return true;
        } else {
            return false;
        }
    }

    //crea al usuario, lo guarda en la base de datos y lo loguea
    const onRegister = async (e) => {  
        e.preventDefault();
        if (!acceptTerms) {
            setError('Debes aceptar los términos y condiciones.');
            return
        }
        else if(email === ""){
            setError("Ingrese un email");
            return;
            //validar email
        } else if(email.indexOf("@") === -1 || email.indexOf(".") === -1 || email.indexOf(" ") !== -1 || email.indexOf("@") === 0 || email.indexOf(".") === 0 || email.indexOf("@") === email.length - 1 || email.indexOf(".") === email.length - 1){
            setError("Ingrese un email válido");
            return;
        } else if(name === ""){
            setError("Ingrese un nombre de usuario");
            return;
        } else if(password === ""){
            setError("Ingrese una contraseña");
            return;
        } else if(confirmPassword === ""){
            setError("Confirme su contraseña");
            return;
        } else if(password !== confirmPassword){
            setError("Las contraseñas no coinciden");
            return;
        }
        //crear usuario
        setLoading(true);
        const reg = await UserAPI.createUser(email, name, password, userType);
        if (!reg[0]){
            setLoading(false);
            if (reg[1] === "Email already exists") setError("El email ingresado ya está en uso");
            else if (reg[1] === "Username already exists") setError("El nombre de usuario ingresado ya está en uso");
            else setError("Ocurrió un error al crear el usuario");
            return;
        }
        //loguear usuario
        let log = await login(email, password);
        setLoading(false);
        if(!log){
            setError("No se pudo iniciar sesión automáticamente, intente iniciar sesión manualmente con la cuenta creada");
        } else {
            setError("");
            setShowLogin(false);
        }
    }

    //loguea al usuario
    const onLogin = async (e) => {
        e.preventDefault();
        if(email === ""){
            setError("Ingrese un email");
            return;
            //validar email
        } else if(email.indexOf("@") === -1 || email.indexOf(".") === -1 || email.indexOf(" ") !== -1 || email.indexOf("@") === 0 || email.indexOf(".") === 0 || email.indexOf("@") === email.length - 1 || email.indexOf(".") === email.length - 1){
            setError("Ingrese un email válido");
            return;
        } else if(password === ""){
            setError("Ingrese una contraseña");
            return;
        }
        setLoading(true);
        let log = await login(email, password);
        setLoading(false);
        if(!log){
            setError("Usuario o contraseña incorrecta");
        } else {
            setError("");
            // si estamos en el index, nos movemos a la pagina de mascotas
            if(window.location.pathname === "/" || window.location.pathname === "/foros"){
                window.location.href = "/perfil";
            } else {
                setShowLogin(false);
            }
        }
    }

    const onRecover = async (e) => {
        e.preventDefault();
        if(email === ""){
            setError("Ingrese un email");
            return;
            //validar email
        } else if(email.indexOf("@") === -1 || email.indexOf(".") === -1 || email.indexOf(" ") !== -1 || email.indexOf("@") === 0 || email.indexOf(".") === 0 || email.indexOf("@") === email.length - 1 || email.indexOf(".") === email.length - 1){
            setError("Ingrese un email válido");
            return;
        }
        setLoading(true);
        //enviar email de recuperacion
        const res = await UserAPI.recoverPassword(email);
        setLoading(false);
        if(res){
            setError("Se ha enviado un email de recuperación a su correo");
        } else {
            setError("Ocurrió un error al enviar el email de recuperación");
        }
    }

    
    return (
        <Modal
        isOpen={showLogin}
        onRequestClose={() => setShowLogin(false)}
        contentLabel="Login"
        className="modal"
        style={{
            overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 100,
            },
        }}
        >
            <div style={{backgroundColor: "white", width: "90%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: 25, maxWidth: 600, zIndex: 100}}>
            <div style={{backgroundColor: "rgba(232, 222, 201, 0.9)", textAlign: "center", padding: 15, borderRadius: 25}} className="login">
            {showState === "Register" && 
                <>
                <h1>Regístrate</h1>
                <p>
                    Regístrate ahora y obtén 12 meses de membresía premium gratis.
                </p>
                <span style={{color: "red"}}>{error}</span>
                <form style={{display: "flex", flexFlow:"column"}}>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="text" placeholder="Nombre de usuario" value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="password" placeholder="contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <input type="password" placeholder="repita contraseña" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    <label htmlFor="terminosCondiciones">
                        <input type="checkbox" id="terminosCondiciones" checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)}/> Acepto los <a href="/Terminos y condiciones pawzone.pdf" target="_blank" rel="noreferrer noopener">términos y condiciones</a>
                    </label>
                    <button onClick={onRegister}>Registrarse</button>
                </form>
                <div className="login-change_state">
                    <p>¿Olvidaste tu contraseña?</p>
                    <button 
                        onClick={() => setShowState("Recover")}>Recuperar contraseña</button>
                </div>
                <div className="login-change_state">
                    <p>¿Ya tienes cuenta?</p>
                    <button 
                        onClick={() => setShowState("Login")}
                        >Iniciar sesión</button>
                </div>
                </>
            }
            {showState === "Login" &&
                <>
                <h1>Iniciar sesión</h1>
                <span style={{color: "red"}}>{error}</span>
                <form style={{display: "flex", flexFlow:"column"}}>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <input type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <button onClick={onLogin}>Iniciar sesión</button>
                </form>
                <div className="login-change_state">
                    <p>¿Olvidaste tu contraseña?</p>
                    <button 
                        onClick={() => setShowState("Recover")}>Recuperar contraseña</button>
                </div>
                <div className="login-change_state">
                    <p>¿No tienes cuenta?</p>
                    <button 
                        onClick={() => setShowState("Register")}>Registrarse</button>
                </div>
                </>
            }
            {showState === "Recover" &&
                <>
                <h1>Recuperar contraseña</h1>
                <span style={{color: "red"}}>{error}</span>
                <form style={{display: "flex", flexFlow:"column"}}>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <button onClick={onRecover}>Recuperar contraseña</button>
                </form>
                <div className="login-change_state">
                    <p>¿Ya tienes cuenta?</p>
                    <button 
                        onClick={() => setShowState("Login")}>Iniciar sesión</button>
                </div>
                <div className="login-change_state">
                    <p>¿No tienes cuenta?</p>
                    <button 
                        onClick={() => setShowState("Register")}>Registrarse</button>
                </div>
                </>
            }
            {loading && 
                <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} >
                <ReactLoading type={"spin"} color={"black"} height={50} width={50} />
                </div>
            }
            </div>
            </div>
        </Modal>
    );
    }

export default Login;