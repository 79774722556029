import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


/**
 * ScrollToTop component
 * @description componente que se encarga de hacer el scroll to top cuando se cambia de pagina
 */
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;