import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as PetApi from "../api/apiPet";
import * as MedicineApi from "../api/apiMedicines.ts";
import MascotasItem from "../components/macotasItem";
import MascotaDetails from "../components/mascotas/mascotaDetails";
import CreatePet from "../components/mascotas/createpet";
import { useSelector, useDispatch } from "react-redux";
import { setPetData } from "../redux/actions/petActions.js";
import ReactLoading from 'react-loading';

const Mascotas = () => {
    const user = useSelector(state => state.user.userData);
    const {id} = useParams();
    const pets = useSelector(state => state.pet.petData);
    const [actualPet, setActualPet] = useState({id: "", name: "", breed: "", birthdate: "", weight: 0, owner: ""});
    const [medicines, setMedicines] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        if (!user) navigate("/");
        if (user.id) {
            if (pets.length === 0) {
                const getPets = async () => {
                    setLoading(true);
                    const pets = await PetApi.getPetsByOwner(user.id);
                    dispatch(setPetData(pets));
                    setLoading(false);
                    if (pets.length > 0) {
                    setActualPet(pets[0]);
                    } else {
                        setActualPet(null);
                    }
                }
                getPets();
            }
        }
    }, [user]);

    useEffect(()=>{
        const getMedicines = async () => {
            const meds = await MedicineApi.getMedicines();
            setMedicines(meds);
        }
        getMedicines();
    }, []);

    useEffect(()=>{
        if (id) {
            if (id === "nueva") {
                setActualPet(null);
                setIsOpen(true);
                return;
            }
            const pet = pets.find(pet => pet.id == id);
            setActualPet(pet);
        } else {
            if (pets.length > 0)
                setActualPet(pets[0]);
        }
    }, [id, pets]);
    return (
        <>
        <CreatePet userId={user?.id ?  user.id : ""} isOpen={isOpen} onClose={()=>{setIsOpen(false); navigate("/mascotas")}} />
        <section style={{
            textAlign: "center",
            maxWidth: 1000,
            margin: "auto",
        }}>
            <div style={{
                display: "flex",
                overflow: "auto",
                height: 180,
            }}>
                {loading && 
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 200,
                        height: 150,
                    }}>
                        <ReactLoading type={"spin"} color={"black"} height={50} width={50} />
                    </div>
                }
                {pets.map(pet => (
                    <button 
                        style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                        }}
                        key={pet.id} 
                        onClick={()=>navigate(`/mascotas/${pet.id}`)}
                    >
                        <MascotasItem mascota={pet}  />
                    </button>
                ))}
                {(!loading && pets.length <= 10) && 
                <button
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "10px",
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        width: 100,
                    }}
                    onClick={()=>{
                        setIsOpen(true);
                    }}
                >
                    <div style={{
                        backgroundColor: "rgba(232, 222, 201, 0.5)",
                        borderRadius: "50%",
                        height: 100,
                        width: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                                fontSize: 50,
                                color: "black"
                            }}
                        />
                    </div>
                    <h4 style={{
                        color: "black",
                        textDecoration: "none",
                    }} >Nueva mascota</h4>
                </button>
                }
            </div>
        </section>
        <MascotaDetails mascota={actualPet} userId={user?.id ? user.id : ""} medicines={medicines} />
        </>
    );
}

export default Mascotas;