export const setForoData = (foroData) => {
    return {
        type: 'SET_FORO_DATA',
        payload: foroData.map((item) => ({
            ...item,
            created: item.created.toDate().toLocaleDateString(),
        })),
    };
}

export const clearForoData = () => {
    return {
        type: 'CLEAR_FORO_DATA',
    };
};

export const addForoData = (foroData, foroId) => {
    return {
        type: 'ADD_FORO_DATA',
        payload: {
            data: foroData.map((item) => ({
                ...item,
                date: item.date.toDate().toLocaleDateString(),
            })),
            id: foroId,
        },
    };
};

export const setMsgToForo = (msgData) => {
    return {
        type: 'SET_MSG_TO_FORO',
        payload: msgData,
    };
};