import { apiURL } from './api';
import { 
    sendCreatePetEvent,
    sendWatchPetEvent,
    sendAddDewormerEvent,
    sendAddVaccineEvent,
    sendAddHistoryEvent,

 } from './apiAnalytics';

/**
 * Función que crea una mascota
 * @param {string} userId id del usuario
 * @param {string} name nombre de la mascota
 * @param {string} species especie de la mascota
 * @param {string} breed raza de la mascota
 * @param {string} birth fecha de nacimiento de la mascota
 * @param {string} weight peso de la mascota
 * @param {string} color color de la mascota
 * @returns {boolean} retorna verdadero si creo la mascota
 */
export const createPet = async (userId, name, species, breed, birth, weight, color, sex) => {
    try {
        const response = await fetch(apiURL+"/pets/create/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId,
                name: name,
                species: species,
                breed: breed,
                birthdate: birth,
                weight: weight,
                color: color,
                sex: sex,
            })
        });
        if (response.ok) {
            sendCreatePetEvent(userId);
            return true;
        } else {
            console.error("Error al crear la mascota");
            return false;
        }
    } catch (error) {
        console.error("Transaction failed: ", error);
        return false; // La transacción falló
    }
}

/**
 * Función que borra una mascota y sus relaciones (vacunas, historial médico y relación con el usuario)
 * @param {string} petId id de la mascota
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si borro la mascota
 */
export const deletePet = async (petId, userId) => {
    try {
        const response = await fetch(apiURL+"/pets/delete/"+petId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al borrar la mascota");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * Función que obtiene las mascotas de un usuario
 * @param {string} userId id del usuario
 * @returns {array} retorna un arreglo con las mascotas del usuario
 */
export const getPetsByOwner = async (userId) => {
    try {
        const response = await fetch(apiURL+"/pets/owner/"+userId+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error("Error al obtener las mascotas del usuario");
            return [];
        }
    } catch (error) {
        console.error("Error getting pets by owner:", error);
        return [];
    }
}


/**
 * Agrega una vacuna a una mascota
 * @param {string} petId id de la mascota
 * @param {string} date fecha de la vacuna
 * @param {string} name nombre de la vacuna
 * @param {string} userId id del usuario que agrega la vacuna
 * @returns {boolean} retorna verdadero si agrego la vacuna
 */
export const addVaccineToPet = async (petId, date, name, userId, reminder="") => {
    try {
        const response = await fetch(apiURL+"/vaccines/create/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                petId: petId,
                date: date,
                name: name,
                userId: userId,
                reminder: reminder
            })
        });
        if (response.ok) {
            sendAddVaccineEvent(userId, petId);
            return true;
        } else {
            console.error("Error al crear la vacuna");
            return false;
        }
    }   catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * Función que borra una vacuna de una mascota
 * @param {string} vaccineId id de la vacuna
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si borro la vacuna
 */
export const deleteVaccineFromPet = async (vaccineId, userId) => {
    try {
        const response = await fetch(apiURL+"/vaccines/delete/"+vaccineId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al borrar la vacuna");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * agrega una consulta médica a una mascota
 * @param {string} petId id de la mascota
 * @param {string} date fecha de la consulta
 * @param {string} diagnosis diagnóstico
 * @param {array} treatment tratamiento
 * @param {string} userId id del usuario que agrega la consulta
 * @returns 
 */
export const addMedicalHistoryToPet = async (petId, date, diagnosis, treatment, userId) => {
    try {
        const response = await fetch(apiURL+"/medicalHistory/create/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                petId: petId,
                date: date,
                diagnosis: diagnosis,
                treatment: treatment,
                userId: userId
            })
        });
        if (response.ok) {
            sendAddHistoryEvent(userId, petId);
            return true;
        } else {
            console.error("Error al crear la consulta médica");
            return false;
        }
    }   catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * Función que borra una consulta médica de una mascota
 * @param {string} medicalHistoryId id de la consulta médica
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si borro la consulta médica
 */
export const deleteMedicalHistoryFromPet = async (medicalHistoryId, userId) => {
    try {
        const response = await fetch(apiURL+"/medicalHistory/delete/"+medicalHistoryId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al borrar la consulta médica");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * Agrega una desparsitación a una mascota
 * @param {string} petId id de la mascota
 * @param {string} date fecha de la desparsitación
 * @param {string} name nombre de la desparsitación
 * @param {string} type tipo de desparsitación
 * @param {string} userId id del usuario que agrega la desparsitación
 * @returns {boolean} retorna verdadero si agrego la desparsitación
 */
export const addDewormingToPet = async (petId, date, name, type, userId, reminder=false) => {
    try {
        const response = await fetch(apiURL+"/dewormings/create/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                petId: petId,
                date: date,
                name: name,
                type: type,
                userId: userId,
                reminder: reminder
            })
        });
        if (response.ok) {
            sendAddDewormerEvent(userId, petId);
            return true;
        } else {
            console.error("Error al crear la desparsitación");
            return false;
        }
    }   catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * Función que borra una desparsitación de una mascota
 * @param {string} dewormingId id de la desparsitación
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si borro la desparsitación
 */
export const deleteDewormingFromPet = async (dewormingId, userId) => {
    try {
        const response = await fetch(apiURL+"/dewormings/delete/"+dewormingId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al borrar la desparsitación");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * Obtiene el historial médico de una mascota
 * @param {string} petId id de la mascota
 * @returns {array} retorna un arreglo con el historial médico de la mascota
 */
export const getMedicalHistoryByPet = async (petId) => {
    try {
        const response = await fetch(apiURL+"/medicalHistory/pet/"+petId+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error("Error al obtener el historial médico de la mascota");
            return [];
        }
    } catch (error) {
        console.error("Error getting medical history by pet:", error);
        return [];
    }
}


/**
 * Obtiene las vacunas de una mascota
 * @param {string} petId id de la mascota
 * @returns {array} retorna un arreglo con las vacunas de la mascota
 */
export const getVaccinesByPet = async (petId) => {
    try {
        const response = await fetch(apiURL+"/vaccines/pet/"+petId+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error("Error al obtener las vacunas de la mascota");
            return [];
        }
    } catch (error) {
        console.error("Error getting vaccines by pet:", error);
        return [];
    }
}

/**
 * Obtiene las desparsitaciones de una mascota
 * @param {string} petId id de la mascota
 * @returns {array} retorna un arreglo con las desparsitaciones de la mascota
 */
export const getDewormingByPet = async (petId) => {
    try {
        const response = await fetch(apiURL+"/dewormings/pet/"+petId+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error("Error al obtener las desparsitaciones de la mascota");
            return [];
        }
    } catch (error) {
        console.error("Error getting dewormings by pet:", error);
        return [];
    }
}

/**
 * Función para subir una foto de una mascota
 * @param {string} petId id de la mascota
 * @param {File} photo url de la foto
 * @returns {boolean} retorna verdadero si subió la foto
 */
export const updatePetPhoto = async (petId, photo) => {
    try {
        const formData = new FormData();
        formData.append("petId", petId);
        formData.append("photo", photo);
        const response = await fetch(apiURL+"/pets/photo/"+petId+"/",{
            method: "POST",
            body: formData
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al subir la foto");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * Función que actualiza los datos de una mascota
 * @param {string} petId id de la mascota
 * @param {string} name nombre de la mascota
 * @param {string} species especie de la mascota
 * @param {string} breed raza de la mascota
 * @param {string} birth fecha de nacimiento de la mascota
 * @param {string} weight peso de la mascota
 * @param {string} color color de la mascota
 * @param {string} sex sexo de la mascota
 * @param {string} chip chip de la mascota
 * @param {boolean} sterilized esterilizado
 * @param {string} description descripción de la mascota
 * @returns {boolean} retorna verdadero si actualizó la mascota 
 */
export const updatePet = async (petId, name, species, breed, birth, weight, color, sex, chip, sterilized, description) => {
    try {
        const response = await fetch(apiURL+"/pets/update/"+petId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                species: species,
                breed: breed,
                birthdate: birth,
                weight: weight,
                color: color,
                sex: sex,
                chip: chip,
                sterilized: sterilized,
                description: description
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al actualizar la mascota");
            return false;
        }
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

export const addAllergiesToPet = async (petId, allergies) => {
    try {
        const response = await fetch(apiURL+"/pets/allergies/create/"+petId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: allergies
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al agregar las alergias");
            return false;
        }
    }
    catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * Función que borra una alergia de una mascota
 * @param {string} allergyId id de la alergia
 * @returns {boolean} retorna verdadero si borro la alergia
 */
export const deleteAllergyFromPet = async (allergyId) => {
    try {
        const response = await fetch(apiURL+"/pets/allergies/delete/"+allergyId+"/",{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al borrar la alergia");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const addCronicalDiseasesToPet = async (petId, diseases) => {
    try {
        const response = await fetch(apiURL+"/pets/cronicDiseases/create/"+petId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: diseases
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al agregar las enfermedades crónicas");
            return false;
        }
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

export const deleteCronicalDiseaseFromPet = async (diseaseId) => {
    try {
        const response = await fetch(apiURL+"/pets/cronicDiseases/delete/"+diseaseId+"/",{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al borrar la enfermedad crónica");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * Función para compartir una mascota con otro usuario
 * @param {*} petId id de la mascota
 * @param {*} userId id del usuario con el que se comparte la
 * @returns 
 */
export const sharePet = async (petId, userId) => {
    try {
        const response = await fetch(apiURL+"/pets/share/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                petId: petId,
                userId: userId
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al compartir la mascota");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * Función que deja de compartir una mascota con otro usuario
 * @param {*} petId id de la mascota
 * @param {*} userId id del usuario al que se deja de compartir
 * @param {*} ownerId id del usuario que manda la petición
 * @returns 
 */
export const unsharePet = async (petId, userId, ownerId) => {
    try {
        const response = await fetch(apiURL+"/pets/unshare/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                petId: petId,
                userId: userId,
                ownerId: ownerId
            })
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al dejar de compartir la mascota");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
