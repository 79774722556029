import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDog, faCat, faPaw } from "@fortawesome/free-solid-svg-icons";
import { apiURL } from "../api/api";

/** 
 * Mascotas Item component
 * @description componente que muestra una mascota con su nombre en la pagina de perfil
 * @param {Object} mascota - la mascota a mostrar
 */
const MascotasItem = ({ mascota }) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            width: 100,
        }}>
            <div style={{
                backgroundColor: "rgba(232, 222, 201, 0.5)",
                borderRadius: "50%",
                height: 100,
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {(mascota.photo == null || mascota.photo === "" || mascota.photo === "null") ? (
                    <FontAwesomeIcon 
                        icon={mascota.species === "perro" ? faDog : mascota.species === "gato" ? faCat : faPaw}
                        style={{
                            fontSize: 50,
                            color: "black"
                        }}   
                    />
                ) : (
                    <img 
                        src={apiURL + "/media/" + mascota.photo}
                        alt={mascota.name}
                        style={{
                            height: 100,
                            width: 100,
                            borderRadius: "50%",
                        }}
                    />
                )}
            </div>
            <h4 style={{
                color: "black",
                textDecoration: "none",

            }} >{mascota.name}</h4>
        </div>
    );
    };

export default MascotasItem;