import { analytics } from "./api";
import { logEvent } from 'firebase/analytics';


/**
 * funcion  que envia un evento a google analytics
 * @param {string} eventName nombre del evento
 * @param {object} eventParams parametros del evento
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendEvent = async (eventName, eventParams) => {
    try {
        await logEvent(analytics, eventName, eventParams);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * funcion que envia un evento de registro de usuario a google analytics
 * @param {string} email email del usuario
 * @returns {boolean} retorna verdadero si envio el evento
 */


export const sendRegisterEvent = async (email) => {
    try {
        await logEvent(analytics, "register", { email: email });
        if(window.fbq) {
            window.fbq('track', 'CompleteRegistration', {content_name: 'Registro', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de inicio de sesion a google analytics
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendLoginEvent = async (userId) => {
    try {
        await logEvent(analytics, "login", { userId: userId });
        if (window.fbq) {
            window.fbq('track', 'Login', {content_name: 'Inicio de sesion', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de cierre de sesion a google analytics
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendLogoutEvent = async (userId) => {
    try {
        await logEvent(analytics, "logout", { userId: userId });
        if (window.fbq) {
            window.fbq('track', 'Logout', {content_name: 'Cierre de sesion', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de observacion de mascotas a google analytics
 * @param {string} userId id del usuario
 * @param {string} petId id de la mascota
 * @returns {boolean} retorna verdadero si envio el evento
 */

export const sendWatchPetEvent = async (userId, petId) => {
    try {
        await logEvent(analytics, "watch_pet", { userId: userId, petId: petId });
        if (window.fbq) {
            window.fbq('track', 'ViewContent', {content_name: 'Observar mascota', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * funcion que envia un evento de observacion de foro a google analytics
 * @param {string} forumId id del foro
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendWatchForumEvent = async (forumId) => {
    try {
        await logEvent(analytics, "watch_forum", { forumId: forumId });
        if (window.fbq) {
            window.fbq('track', 'ViewContent', {content_name: 'Observar foro', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de creacion de foro a google analytics
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendCreateForumEvent = async (userId) => {
    try {
        await logEvent(analytics, "create_forum", { userId: userId});
        if (window.fbq) {
            window.fbq('track', 'CreateForum', {content_name: 'Crear foro', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de creacion de mascota a google analytics
 * @param {string} userId id del usuario
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendCreatePetEvent = async (userId) => {
    try {
        await logEvent(analytics, "create_pet", { userId: userId });
        if (window.fbq) {
            window.fbq('track', 'CreatePet', {content_name: 'Crear mascota', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un agregar vacuna a google analytics
 * @param {string} userId id del usuario
 * @param {string} petId id de la mascota
 * @param {string} vaccineId id de la vacuna
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendAddVaccineEvent = async (userId, petId) => {
    try {
        await logEvent(analytics, "add_vaccine", { userId: userId, petId: petId});
        if (window.fbq) {
            window.fbq('track', 'AddVaccine', {content_name: 'Agregar vacuna', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de agregar historia clinica a google analytics
 * @param {string} userId id del usuario
 * @param {string} petId id de la mascota
 * @param {string} foodId id del alimento
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendAddHistoryEvent = async (userId, petId) => {
    try {
        await logEvent(analytics, "add_history", { userId: userId, petId: petId});
        if (window.fbq) {
            window.fbq('track', 'AddHistory', {content_name: 'Agregar historia clinica', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * funcion que envia un evento de agregar desparasitante a google analytics
 * @param {string} userId id del usuario
 * @param {string} petId id de la mascota
 * @param {string} foodId id del alimento
 * @returns {boolean} retorna verdadero si envio el evento
 */
export const sendAddDewormerEvent = async (userId, petId) => {
    try {
        await logEvent(analytics, "add_dewormer", { userId: userId, petId: petId });
        if (window.fbq) {
            window.fbq('track', 'AddDewormer', {content_name: 'Agregar desparasitante', status: 'success'});
        }
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}