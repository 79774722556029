import { combineReducers } from 'redux';
import userReducer from './userReducer';
import foroReducer from './foroReducer';
import petReducer from './petReducer';

const rootReducer = combineReducers({
  user: userReducer,
  foro: foroReducer,
  pet: petReducer,
});

export default rootReducer;
