import React from "react";
import NosotrosItem from "../components/index/nosotrosItem";
import FaqItem from "../components/index/faqItem";
import "./index.css";
import Contacto from "../components/index/contacto.tsx";
import {useSelector} from "react-redux";


// se importan las imágenes
import img1 from "../resources/PerroGato.jpeg"
// import img1Avif from "../resources/portada1.avif"
// import img2 from "../resources/portada3.png"
// import img2Avif from "../resources/portada3.avif"
import imgFicha from "../resources/Ficha.png"
import andres from "../resources/andres.jpeg"
import andresAvif from "../resources/andres.avif"
import daniel from "../resources/daniel.jpeg"
import danielAvif from "../resources/daniel.avif"





/**
 * This is the main page of the application
 * 
 */
const IndexPage = ({setShowLogin}) => {

  const user = useSelector(state => state.user.userData);

  return (
    <>
      <div className="page-body">
        <section className="hero">
          <div className="white-opacity">
            <div className="hero-text overlay">
              <h1>
                Una <span className="nueva">nueva</span> forma de entender la <br></br> relación con tu mascota
              </h1>
              <p className="parrafo1">
                Encuentra en un solo lugar una ficha con recordatorios para tu mascota y una comunidad con veterinarios para que  resolver todas tus dudas.
              </p>
            </div>
          </div>
        </section>
        <section className="choose" >
          <h2>Ficha clínica digital</h2>
          <div className="choose-img">
            <img src={imgFicha} alt="foto ficha"/>
          </div>
          <div className="choose-text">
            <p className="Descubre">
            ¡Descubre <b>la mejor ficha veterinaria</b>  para tu mascota!
            </p>
            <li>
              <b>Registra todas las vacunas</b> de tú mascota y <b>recibe recordatorios automáticos</b> para nunca olvidarte
            </li>
            <li>
              Mantener un <b>registro de todos los procedimientos veterinarios de tu mascota.</b>
            </li>
            <li>
              <b>Olvídate de los papeles perdidos</b> o el estrés por no poder acceder a los datos de tu mascota.
            </li>
            <p className="Descubre">
            ¡Haz que el cuidado de tu mascota sea más fácil y eficiente que nunca con nuestra ficha veterinaria!
            </p>
          </div>
          <h2 className="Acceder">
            {user ? // ¿Esta Loggeado?
              <a href="/perfil">
                Acceder a mi ficha
              </a> // Si 
              
              : // TODO: terminar esto

              <button className="BotonAcceder" onClick={() => {setShowLogin(true)}}>
                <h2>Acceder a mi ficha</h2>
              </button> // No
            }
          </h2>
        </section>
        <section className="PorqueElegirnos" >
          <h2>¿Por qué elegirnos?</h2>
          <div className="PorqueElegirnos-img">
            <img src={img1} alt="foto perrito"/>
          </div>
          <div className="PorqueElegirnos-text">
            <div className="algoNuevo">
            <p>
              En PawZone queremos construir una nueva forma de entender la relación con tu mascota y tenemos el compromiso y las herramientas para hacerlo realidad.
            </p>
            <p>
              ¿Te gustaría algo nuevo?
            </p>
            </div>
            <a className="Escribenos" href="#contacto">
              <b>Escribenos</b>
            </a>
          </div>
        </section>
        <section style={{textAlign: "center", padding: 25, maxWidth: 1000, margin: "auto"}}>
          <h2>¿Quienes somos?</h2>
          <p>
          PawZone emergió de una chispa de innovación de un equipo de estudiantes de ingeniería de la Universidad de Chile, <b>unidos por su pasión por las mascotas y la tecnología.</b>
          </p>
          <p>
          Al enfrentarse a los desafíos cotidianos del cuidado de sus propios animales, identificaron la necesidad de una solución integral que abordara la gestión de la salud y bienestar de las mascotas de una manera moderna y accesible. Inspirados por este desafío, transformaron su visión en realidad, dando vida a PawZone.
          </p>
          <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap"}}>
            <NosotrosItem 
              imagen={andres}
              ligthImage={andresAvif}
              nombre="Andres Oñate"
              puesto="CEO y Fundador"
            />
            <NosotrosItem 
              imagen={daniel}
              ligthImage={danielAvif}
              nombre="Daniel Radrigán"
              puesto="CTO y Fundador"
            />
          </div>
        </section>
        <section style={{textAlign: "center", padding: 25, maxWidth: 1000, margin: "auto"}}>
          <h2>Preguntas frecuentes</h2>
          <FaqItem
            question="¿Cómo puedo registrar a mi mascota en PawZone?"
            answer="Para registrar a tu mascota en PawZone, simplemente debes crear una cuenta de usuario, ir a Mi mascota y luego agregar Mascota. Necesitarás proporcionar información básica como el nombre, edad, raza y cualquier detalle relevante sobre su salud."
          />
          <FaqItem 
            question="¿Qué beneficios tiene la ficha clínica digital de mi mascota?"
            answer="La ficha clínica digital permite un seguimiento detallado de la salud de tu mascota. Facilita el almacenamiento de su historial médico, vacunaciones, alergias, tratamientos y citas veterinarias. Esto es especialmente útil para tener un control preciso sobre su salud y para compartir información vital con profesionales veterinarios rápidamente."
          />
          <FaqItem 
            question="¿Cómo puedo utilizar el foro de PawZone?"
            answer="El foro de PawZone está disponible para todos los usuarios registrados. Es un espacio para compartir experiencias, consejos y dudas sobre el cuidado de las mascotas. Para mantener un ambiente positivo y útil, pedimos a todos los usuarios que sigan nuestras directrices de respeto y cordialidad."
          />
          <FaqItem 
            question="¿Puedo acceder a PawZone desde mi teléfono móvil?"
            answer="Sí, PawZone es totalmente accesible desde dispositivos móviles. Nuestra plataforma está diseñada para ser amigable y fácil de usar en cualquier dispositivo, asegurando que puedas acceder a la información y servicios de tus mascotas en cualquier momento y lugar."
          />
        </section>
        <section id="contacto">
          <Contacto/>
        </section>
      </div>
    </>
  );
}

export default IndexPage;