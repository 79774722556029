import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';


/**
 * FaqItem component
 * componente que muestra un item de preguntas frecuentes con su respuesta como acordeón
 * @param {string} question - la pregunta a mostrar
 * @param {string} answer - la respuesta a mostrar
 */
const FaqItem = ({ question= "¿Lorem impsum?", answer="lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, vestibulum mi id, varius mi. Fusce nec semper nunc. lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, vestibulum mi id, varius mi. Fusce nec semper nunc." }) => {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <Accordion 
            style={{
                border: "1px solid #f0f0f0",
                borderRadius: "10px",
                margin: "10px 0",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)"
            
            }}
            className="faq-item" expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
            <AccordionSummary 
                expandIcon={isOpen ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowRight} />}>
                <h3>{question}</h3>
            </AccordionSummary>
            <AccordionDetails>
                <p>{answer}</p>
            </AccordionDetails>
        </Accordion>
    );
};

export default FaqItem;