import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as PetApi from "../../../api/apiPet.js";
import ReactLoading from 'react-loading';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch } from 'react-redux';
import { setPetData } from "../../../redux/actions/petActions.js";
import dayjs from 'dayjs';
import "./vetHistory.css" // Hay estilos compartidos con vaccine.css

const VetHistory = ({medicalHistory, pet, userId, setMedicalHistory, medicines}) => {
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newMedicalHistory, setNewMedicalHistory] = useState({
        date: "",
        diagnosis: "",
    });
    const [petMedicines, setPetMedicines] = useState([]);
    const [structureMedicalHistory, setStructureMedicalHistory] = useState([]);
    const [showTreatment, setShowTreatment] = useState([]);
    const [edditing, setEdditing] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (medicalHistory) {
            let medicalHistoryArray = medicalHistory;
            medicalHistoryArray.sort((a, b) => {
                return b.date.seconds - a.date.seconds;
            });
            setStructureMedicalHistory(medicalHistoryArray);
            let showTreatment = [];
            for (let i = 0; i < medicalHistoryArray.length; i++) {
                showTreatment.push(false);
            }
            setShowTreatment(showTreatment);
        }
    }, [medicalHistory]);

    const handleAddMedicalHistory = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(petMedicines);
        const response = await PetApi.addMedicalHistoryToPet(pet.id, newMedicalHistory.date, newMedicalHistory.diagnosis, petMedicines, userId);
        if (response) {
            /* newMedicalHistory.date =Timestamp.fromDate(new Date(newMedicalHistory.date));
            newMedicalHistory.treatment = petMedicines;
            setMedicalHistory([...medicalHistory, newMedicalHistory]); */
            setNewMedicalHistory({
                date: "",
                diagnosis: "",
            });
            setPetMedicines([]);
            setOpenForm(false);
            reloadPets();
        } else {
            alert("Error al añadir el historial médico");
        }
        setIsLoading(false);
    }

    // recargar la lista de mascotas
    const reloadPets = async () => {
        const pets = await PetApi.getPetsByOwner(userId);
        dispatch(setPetData(pets));
    }

    return (
      <div className="body-history">
        {/* <h3>Historial Veterinario</h3> */}
        {!openForm &&
                <button className="edit-button" onClick={() => setEdditing(!edditing)}>
                    {edditing ? "Terminar edición" : "Editar"}
                </button>
            }
        {!openForm ? (
          <button className="añadir-button" onClick={() => setOpenForm(true)}>
            <FontAwesomeIcon icon={faPlus} />
            Añadir registro
          </button>
        ) : (
          <form className="form-añadir">
            {isLoading && (
              <div className="load">
                <ReactLoading
                  type="spin"
                  color="black"
                  height={50}
                  width={50}
                />
              </div>
            )}
            <div className="close-div">
              <button className="close-button" onClick={() => setOpenForm(false)}>
                Cerrar
              </button>
            </div>
            <h4>Añadir historial Veterinario</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Fecha"
                maxDate={dayjs(new Date())}
                minDate={dayjs(pet?.birthdate) || dayjs("2000-01-01")}
                value={
                  newMedicalHistory.date
                    ? dayjs(new Date(newMedicalHistory.date))
                    : null
                }
                onChange={(newValue) => {
                  const dateValue = newValue
                    ? newValue.toDate().toISOString().slice(0, 10)
                    : "";
                  setNewMedicalHistory({
                    ...newMedicalHistory,
                    date: dateValue,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <label>Diagnóstico/ Procedimiento</label>
            <TextField
              label="Diagnóstico o procedimiento"
              multiline
              variant="outlined"
              value={newMedicalHistory.diagnosis}
              onChange={(e) =>
                setNewMedicalHistory({
                  ...newMedicalHistory,
                  diagnosis: e.target.value,
                })
              }
              required
            />
            {petMedicines.map((medicine, index) => (
              <div key={index} className="div-añadir-medicamento">
                <label>Medicamento</label>
                <Autocomplete
                  freeSolo
                  options={medicines}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Medicamento" />
                  )}
                  value={medicine.name}
                  onInputChange={(e, newValue) => {
                    const updatedMedicines = [...petMedicines];
                    updatedMedicines[index].product = newValue;
                    setPetMedicines(updatedMedicines);
                  }}
                />
                <div className="div-dosis-veces">
                  <div className="div-dosis">
                    <TextField
                      label="Dosis"
                      value={medicine.dosis}
                      onChange={(e) => {
                        const updatedMedicines = [...petMedicines];
                        updatedMedicines[index].dosis = e.target.value;
                        setPetMedicines(updatedMedicines);
                      }}
                    />
                  </div>
                  <div className="div-veces-al-dia">
                    <TextField
                      type="number"
                      label="Nº de veces al día"
                      value={medicine.frequency}
                      onChange={(e) => {
                        const updatedMedicines = [...petMedicines];
                        updatedMedicines[index].frequency = e.target.value;
                        setPetMedicines(updatedMedicines);
                      }}
                    />
                  </div>
                  <div className="div-duration">
                    <TextField
                      type="number"
                      label="Duración"
                      value={medicine.duration}
                      onChange={(e) => {
                        const updatedMedicines = [...petMedicines];
                        updatedMedicines[index].duration = e.target.value;
                        setPetMedicines(updatedMedicines);
                      }}
                    />
                  </div>
                </div>
                <button className="eliminar-medicamento-button" onClick={(e) => {
                  e.preventDefault();
                    setPetMedicines(petMedicines.filter((_, i) => i !== index));
                  }}>
                    <FontAwesomeIcon icon={faTrash} />
                    Eliminar medicamento
                </button>
              </div>
            ))}
            <button className="añadir-medicamento-button" onClick={(e) => {
                e.preventDefault();
                setPetMedicines([
                  ...petMedicines,
                  {
                    product: "",
                    dosis: "",
                    frequency: "",
                    duration: "",
                  },
                ]);
              }}>
                <FontAwesomeIcon icon={faPlus} />
                Añadir medicamento
            </button>
            <button className="añadir3-button" type="submit" onClick={handleAddMedicalHistory}>
              Añadir
            </button>
          </form>
        )}
        {structureMedicalHistory.length !== 0 && (
          <div>
            {structureMedicalHistory.map((history, index) => (
              <div className="div-consulta" key={index}>
                <p><b>Fecha:</b> {history.date}</p>
                <p><b>Diagnóstico:</b> {history.description}</p>
                <p><b>Tratamiento: <button className="show-treatment-button" onClick={() => {
                  const updatedShowTreatment = [...showTreatment];
                  updatedShowTreatment[index] = !showTreatment[index];
                  setShowTreatment(updatedShowTreatment);
                }
                }>{showTreatment[index] ? "Ocultar" : "Mostrar"}</button>
                  </b></p>

                {showTreatment[index] && (
                <ul>
                  {history.treatment.map((medicine, index) => (
                    <li key={index}>
                      <p><b>Medicamento:</b> {medicine.product}</p>
                      <p><b>Dosis:</b> {medicine.dosis}</p>
                      <p><b>Nº de veces al día:</b> {medicine.frequency}</p>
                      <p><b>Duración:</b> {medicine.days} días</p>
                    </li>
                  ))}
                </ul>
                )}
                {(edditing && history.creator_id === userId) && (
                  <button className="eliminar-button" onClick={async () => {
                    const response = await PetApi.deleteMedicalHistoryFromPet(history.id, userId);
                    if (response) {
                      setStructureMedicalHistory(structureMedicalHistory.filter((_, i) => i !== index));
                    } else {
                      alert("Error al eliminar el historial médico");
                    }
                  }}>
                    <FontAwesomeIcon icon={faTrash} />
                    Eliminar
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
};

export default VetHistory;