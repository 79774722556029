export const setPetData = (petData) => {
    return {
        type: 'SET_PET_DATA',
        payload: petData.map((item) => ({
            ...item,
        })),
    };
}

export const clearPetData = () => {
    return {
        type: 'CLEAR_PET_DATA',
    };
}