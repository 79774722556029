import React, {useState} from "react";
import ReactLoading from 'react-loading';
import * as API from "../../api/api";

const Contacto = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [returnMsg, setReturnMsg] = useState("");
    const handleContactForm = async() => {
        if (!name || !email || !msg) {
            setReturnMsg("Por favor, llene todos los campos");
            return;
        }
        setLoading(true);
        const response = await API.contactForm(name, email, msg);
        setLoading(false);
        if(response){
            setName("");
            setEmail("");
            setMsg("");
            setReturnMsg("Mensaje enviado correctamente");
        } else {
            setReturnMsg("Hubo un error al enviar el mensaje, por favor intente de nuevo");
        }

    }
    return (
        <section className="contact" style={{textAlign: "center", padding: 25, maxWidth: 1000, margin: "auto", position: "relative"}}>
            {loading && 
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255,255,255,0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2
            }}>
                <ReactLoading type={"spin"} color={"#000"} height={50} width={50} />
            </div>
            }
            <h2>Contáctenos</h2>
            <span style={{
                color: returnMsg === "Mensaje enviado correctamente" ? "green" : "red"
            }}>{returnMsg}</span>
            <form>
                <div style={{display: "flex", flexFlow: "column"}}>
                <label htmlFor="name">Nombre:</label>
                <input id="name" className="name" type="text" placeholder="Nombre" required value={name} onChange={(e) => setName(e.target.value)}/>
                <label htmlFor="email">Correo:</label>
                <input id="email" className="email" type="email" placeholder="Correo" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div style={{display: "flex", flexFlow: "column"}}>
                <label htmlFor="msg">Mensaje:</label>
                <textarea id="msg" placeholder="Mensaje" required value={msg} onChange={(e) => setMsg(e.target.value)}>

                </textarea>
                <button 
                    onClick={(e) => {e.preventDefault(); handleContactForm()}}
                >Enviar</button>
                </div>
            </form>
        </section>
    );
};

export default Contacto;