export const setUserData = (userData) => {
    return {
        type: 'SET_USER_DATA',
        payload: userData,
    };
};

export const clearUserData = () => {
    return {
        type: 'CLEAR_USER_DATA',
    };
};
  