

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyAMhpwUaQiSDsCc8ZCZ3iqqIh1TMup0u2A",
    authDomain: "pawzone-de42e.firebaseapp.com",
    projectId: "pawzone-de42e",
    storageBucket: "pawzone-de42e.appspot.com",
    messagingSenderId: "723709402851",
    appId: "1:723709402851:web:19aeeee441bba9bc4bafd6",
    measurementId: "G-R10K680EZS"
  };