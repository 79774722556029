import { useEffect, useState } from "react";
import ReactModal from "react-modal"
import * as PetApi from "../../api/apiPet";
import "./createpet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from 'react-loading';
import { useDispatch } from "react-redux";
import { setPetData } from "../../redux/actions/petActions.js";
/**
 * CreatePet component
 * @description Componente que muestra un formulario dentro de un modal para crear una mascota
 * @param {string} userId - id del usuario logueado
 * @param {boolean} isOpen - estado del modal
 * @param {function} onClose - función para cerrar el modal
 * @module CreatePet
 */
const CreatePet = ({userId, isOpen, onClose}) => {
    const [name, setName] = useState("");
    const [species, setSpecies] = useState("");
    const [breed, setBreed] = useState("");
    const [birth, setBirth] = useState("");
    const [weight, setWeight] = useState(0);
    const [color, setColor] = useState("")
    const [sex, setSex] = useState("");
    const [nameError, setNameError] = useState("");
    const [speciesError, setSpeciesError] = useState("");
    const [breedError, setBreedError] = useState("");
    const [birthError, setBirthError] = useState("");
    const [weightError, setWeightError] = useState("");
    const [colorError, setColorError] = useState("");
    const [sexError, setSexError] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        ReactModal.setAppElement('body');
    }, []);

    // recargar la lista de mascotas
    const reloadPets = async () => {
        const pets = await PetApi.getPetsByOwner(userId);
        dispatch(setPetData(pets));
    }


    const handleCreatePet = async (e) => {
        e.preventDefault();
        // validar campos
        if (name === "") {
            setNameError("Ingrese un nombre");
            return;
        } else if (species === "") {
            setSpeciesError("Ingrese una especie");
            return;
        } else if (breed === "") {
            setBreedError("Ingrese una raza");
            return;
        } else if (birth === "") {
            setBirthError("Ingrese una fecha de nacimiento");
            return;
        } else if (weight === 0) {
            setWeightError("Ingrese un peso");
            return;
        } else if (color === "") {
            setColorError("Ingrese un color");
            return;
        } else if (sex === "") {
            setSexError("Ingrese un sexo");
            return;
        } else {
            setLoading(true);
            const result = await PetApi.createPet(userId,name, species, breed, birth, weight, color, sex);
            if (result) {
                await reloadPets();
                setLoading(false);
                onClose();
            } else {
                setLoading(false);
                alert("Error al crear la mascota");
            }
            
        }
    }


    return (
        <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Create pet modal"
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2
            },
            content: {
                width: '70%',
                minWidth: '300px',
                maxWidth: '600px',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                maxHeight: '90vvh',
                position: 'absolute',
                left: '50%',
                right: '50%',
                transform: 'translate(-50%,0)',
                overflowY: 'auto',
            }
        }}
        >
        <div style={{textAlign: "right"}} className="close-create-pet-modal">
            <button onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
        </div>
        <h1 className="create-pet-title">Crear mascota</h1>
        {!loading &&
        <form className="create-pet-form">
            <div>
                <label>Nombre de la mascota <span style={{color: "red"}}>{nameError}</span></label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div>
                <label>Especie <span style={{color: "red"}}>{speciesError}</span></label>
                {/* hacer una lista desplegable de opciones */}
                <select value={species} onChange={(e) => setSpecies(e.target.value)}>
                    <option value="">Seleccione una especie</option>
                    <option value="perro">Perro</option>
                    <option value="gato">Gato</option>
                    <option value="otro">Otro</option>
                </select>
            </div>
            <div>
                <label>Raza <span style={{color: "red"}}>{breedError}</span></label>
                <input type="text" value={breed} onChange={(e) => setBreed(e.target.value)} required />
            </div>
            <div>
                <label>Fecha de nacimiento <span style={{color: "red"}}>{birthError}</span></label>
                <input 
                    type="date" 
                    value={birth} 
                    onChange={(e) => setBirth(e.target.value)} 
                    required 
                    max={new Date().toISOString().split("T")[0]}
                />
            </div>
            <div>
                <label>Peso (en kg) <span style={{color: "red"}}>{weightError}</span></label>
                <input type="number" value={weight == 0 ? null : weight} onChange={(e) => setWeight(e.target.value)} required />
            </div>
            <div>
                <label>Color <span style={{color: "red"}}>{colorError}</span></label>
                <input type="text" value={color} onChange={(e) => setColor(e.target.value)} required />
            </div>
            <div>
                <label>Sexo <span style={{color: "red"}}>{sexError}</span></label>
                <select value={sex} onChange={(e) => setSex(e.target.value)}>
                    <option value="">Seleccione un sexo</option>
                    <option value="macho">Macho</option>
                    <option value="hembra">Hembra</option>
                </select>
            </div>
            <button className="submit-button" onClick={handleCreatePet}>Crear</button>
        </form>
        }
        {loading && 
        <div style={{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            minHeight: 400,
            }}>
            <ReactLoading type="bubbles" color="#000" height={100} width={100} />
        </div>}
        
        </ReactModal>
    )
}

export default CreatePet;
