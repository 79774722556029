import React, {useState} from "react";
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import * as PetApi from "../../api/apiPet.js";
// import * as PhotoApi from "../../api/apiPhotos.js";
import * as UserApi from "../../api/apiUser.js";
import {  useDispatch } from "react-redux";
import { setPetData } from "../../redux/actions/petActions.js";
import { setUserData } from "../../redux/actions/userActions.js";
import "./editProfileModal.css";

/**
 * Componente que muestra un modal para editar el perfil de un usuario
 * (cambio de nombre, teléfono y foto de perfil) 
 */
const EditProfileModal = ({user, pets, open, onClose}) => {
    const [name, setName] = useState(user.name);
    const [phone, setPhone] = useState(user.phone);
    const [address, setAddress] = useState(user.address);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [errorMessage, setErrorMessage] = useState("");
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [petsList, setPetsList] = useState(pets);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const updateProfilePhoto = async () => {
        if(profilePicture) {
            setLoading(true);
            const res = await UserApi.updateProfilePhoto(user.id, profilePicture);
            setLoading(false);
            if (res) {
                const _user = await UserApi.getUser(user.id);
                dispatch(setUserData(_user));
                onClose();
            } else {
                alert("Error al actualizar la foto de perfil");
            }
        }
    }


    const handleUpdateProfile = async () => {
        try {
            setLoading(true);
            const _res = await UserApi.updateUser(user.id, name, user.email, phone, address, firstName, lastName);
            if (_res[0]) {
                const _user = await UserApi.getUser(user.id);
                dispatch(setUserData(_user));
                setErrorMessage("");
            } else {
                setErrorMessage(_res[1]);
            }
            setLoading(false);
            onClose();
        } catch (error) {
            console.error(error);
        }
    }

    const deletePet = async (petId) => {
        try {
            setLoading(true);
            await PetApi.deletePet(petId, user.id);
            const pets = await PetApi.getPetsByOwner(user.id);
            dispatch(setPetData(pets));
            setPetsList(pets);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            isOpen={open}
            contentLabel="Editar perfil"
            onRequestClose={onClose}
            style={{
                overlay: {
                    zIndex: 2,
                }
            }}
        >
            <div className="modal-content">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdateProfile();
                    }}
                    className="form-editar-perfil"
                >
                    <h2>Editar perfil</h2>
                    <label>Nombre de usuario</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label>Nombre</label>
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <label>Apellido</label>
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <label>Teléfono</label>
                    <input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Dirección</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    
                    <button type="submit" className="button-guardar">Guardar</button>
                </form>
                {/** forms para subir foto de perfil y visualizar esta antes de cambiarla */}
                <div className="div-edit-pfp">
                    <h2>Foto de perfil</h2>
                        {profilePicture && (
                            <img
                            src={URL.createObjectURL(profilePicture)}
                            alt="Foto de perfil"
                            className="pfp"
                            />
                        )}
                    <div className="div-edit-pfp-buttons">
                        <input 
                            type="file" 
                            accept="image/*"
                            onChange={(e) => {
                                if (e.target.files) {
                                    setProfilePicture(e.target.files[0]);
                                }
                            }}
                        />
                        <button className="button-actualizar-foto" onClick={() => {updateProfilePhoto()}}>
                                Actualizar Foto
                            </button>
                    </div>
                </div>         
                <div>
                    <h2>Mis mascotas</h2>
                    {petsList.length === 0 && <p>No tienes mascotas registradas</p>}
                    <ul>
                        {petsList.map((pet) => (
                            <li key={pet.id}>
                                <span>{pet.name}</span>
                                <button
                                    onClick={() => {
                                        const userResponse = window.confirm(
                                            `¿Estás seguro de borrar a ${pet.name}?`
                                        );
                                        if (userResponse) {
                                            deletePet(pet.id);
                                        }
                                    }}
                                >Borrar</button>
                            </li>
                        ))}
                    </ul>
                </div>
                {loading && (
                    <div className="div-loading">
                    <ReactLoading
                        type={"spinningBubbles"}
                        color={"#000000"}
                        height={50}
                        width={50}
                    />
                    </div>
                )}
            </div>
        </Modal>
    );


}

export default EditProfileModal;