import { apiURL } from './api';


// Estructura de la colección de medicamentos
export interface Medicine {
    id: number;
    product: string;
}


/**
 * Función que obtiene todos los medicamentos de la base de datos
 * @returns {Promise<Array<Medicine>>} Promesa con el arreglo de medicamentos
 */
export async function getMedicines() {
    try {
        const response = await fetch(`${apiURL}/medicines/get/`);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('Error al obtener los medicamentos:', response.statusText);
            return [];
        }
    }
    catch (error) {
        console.error('Error al obtener los medicamentos:', error);
        return [];
    }

}